import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(window.localStorage.getItem('isLoggedIn') === 'true');

    const login = (username,password) => {
        console.log('username,password',username,password)
        window.localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        // const channel = new BroadcastChannel('userChannel');
        // channel.postMessage({ username: username });
        // localStorage.setItem('username', username);
        console.log('window.localStorage.getItem',window.localStorage.getItem('username'))
        console.log('window.localStorage.getItem',window.localStorage.getItem('password'))
        // console.log('username,password',username,password)
        // window.localStorage.getItem('isLoggedIn')
        // window.localStorage.getItem('isLoggedIn')

        setIsLoggedIn(true);
    };

    const logout = () => {
        window.localStorage.setItem('isLoggedIn', false);
        setIsLoggedIn(false);
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);