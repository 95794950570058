import React, { useState, useEffect, useRef } from 'react';
import GridWrapper from "../common/GridWrapper/GridWrapper";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Button, Slider } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Sample from '../../pages/images/PO.jpg';
import { ReactPictureAnnotation } from "react-picture-annotation";
import './trainingSetup.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { InputAdornment, MenuItem } from "@mui/material";
import axios from 'axios';
import _ from 'lodash';
import { useNavbar } from '../Navbar/NavbarContext';
import { Add, Remove, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { GlobalWorkerOptions, getDocument } from "pdfjs-dist/legacy/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

GlobalWorkerOptions.workerSrc = pdfWorker;


const apiURL = process.env.REACT_APP_BACKEND_API_URL;

// const LightTooltip = styled(({ className, ...props }) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: 'rgba(0, 0, 0, 0.87)',
//         boxShadow: theme.shadows[1],
//         fontSize: 11,
//     },
// }));

const ImageArray = [
    { 'imagename': Sample }
]
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// let tempAnnotation =[
//     {id: 'a8syEs', mark: {x: 148.75000000000009, y: 277.75, width: 145.74999999999997, height: 60.5, type: 'RECT'}},
//     {id: 'EtHEGT', mark:{x: 1386.25, y: 176, width: 247.5, height: 104.5, type: 'RECT',  labelColor: '#9B5DC2'}}
//  ]

let tempdata = [
    { id: '198', labelName: 'Purchase Order Date', annotations: '0/10', labelColor: '#A9E120', extraction_type: 'Field', annotation_text: [{ bbox_ID: 'N67FRS', annoted_Text: 'mspark', TextBoxDetails: [{ id: 0, bbox: [159, 193, 414, 189, 415, 235, 160, 239], text: 'mspark', start_x: 160, start_y: 189 }], annotationDetails: { id: 'N67FRS', mark: { x: 126.75000000000009, y: 165, width: 368.5, height: 88, type: 'RECT' } } }] },
    { id: '216', labelName: 'Unit Price', annotations: '0/10', labelColor: '#9B5DC2', extraction_type: 'Table', annotation_text: [{ bbox_ID: '', annoted_Text: 'Invoice', TextBoxDetails: [{ id: 40, bbox: [1398, 210, 1585, 210, 1585, 248, 1398, 248], text: 'Invoice', start_x: 1398, start_y: 210 }], annotationDetails: { id: 'P6PZYN', mark: { x: 1375.25, y: 165, width: 253, height: 104.5, type: 'RECT' } } }, { bbox_ID: 'fTzCRK', annoted_Text: 'Type', TextBoxDetails: [{ id: 70, bbox: [876, 703, 931, 705, 930, 726, 875, 724], text: 'Type', start_x: 875, start_y: 705 }], annotationDetails: { id: 'fTzCRK', mark: { x: 866.5000000000001, y: 690.25, width: 79.75, height: 46.75, type: 'RECT' } } }] }
]

// let tempAnnotation = tempdata.flatMap(item =>
//     item.annotation_text.map(annotation => annotation.annotationDetails)
// );

export default function ImageAnnotator() {
    const [selectedLabel, setSelectedLabel] = useState({ id: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(150);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [selectedBoxId, setSelectedBoxId] = useState(null);
    const [boxId, setBoxId] = useState(null);
    const [annotationColor, setAnnotationColor] = useState(null);
    const [tempAnnotationColor, setTempAnnotationColor] = useState(null);
    const [selectedAnnotation, setSelectedAnnotation] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [rightClick, setRightClick] = useState(false);
    const navigate = useNavigate();
    const [ocrDatas, setOcrDatas] = useState([]);

    const [loadedImages, setLoadedImages] = useState([]);
    // getting data from previous page
    const location = useLocation();
    const { labelsInitial } = location.state || {};

    const { image_id } = location.state || {};

    const [imageId, setImageId] = useState(image_id);

    const [line_image_details, setLineImageDetails] = useState([]);
    const [defaultLabels, setDefaultLabels] = useState(labelsInitial);
    const [annotations, setAnnotations] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(300);

    const [searchValue, setSearchValue] = useState("");
    const [filteredImages, setFilteredImages] = useState([]);

    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);

        // Filter and get the top 5 matching file names
        const matches = loadedImages
            .filter((image) => image.fileName.toLowerCase().includes(value))
            .slice(0, 5);
        setFilteredImages(matches);
    };

    const handleSelectImage = (selectedImage) => {
        const index = loadedImages.findIndex((image) => image.imageId === selectedImage.imageId);
        setImageId(selectedImage.imageId);
        setCurrentPage(index + 1);
        setSearchValue(""); // Clear the search bar
        setFilteredImages([]); // Clear the filtered results
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => {
                const newPage = prevPage + 1;
                setImageId(loadedImages[newPage - 1].imageId);
                return newPage;
            });
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => {
                const newPage = prevPage - 1;
                setImageId(loadedImages[newPage - 1].imageId);
                return newPage;
            });
        }
    };


    const handleDelete = async () => {
        // Remove the row locally
        // const updatedRows = loadedImages.filter((_, i) => i !== index);
        // setLoadedImages(updatedRows);

        console.log('ID', imageId);

        // try {
        //   const response = await axios.post(apiURL + '/get/delete-image', { imageId });

        //   if (response.status === 200) {
        //     console.log('Image deleted successfully');
        //     loadImages();
        //   } else {
        //     console.error('Failed to delete image', response.statusText);
        //   }
        // } catch (error) {
        //   console.error('Error deleting image', error); 
        // }
    };

    const handleZoomChange = (event, newValue) => {
        setZoomLevel(newValue);
        // Add logic to adjust zoom here
    };
    function updateEmptyBBoxIDs(data) {
        data.forEach(item => {
            if (item.annotation_text && item.annotation_text.length > 0) {
                item.annotation_text.forEach(annotation => {
                    if (!annotation.bbox_ID) {
                        annotation.bbox_ID = Math.random().toString(36).substring(2, 7);
                    }
                });
            }
        });
    }
    useEffect(() => {
        updateEmptyBBoxIDs(defaultLabels);
    }, [defaultLabels])

    const [annotedLabels, setAnnotedLabels] = useState();
    const [maxAnnotations, setMaxAnnotations] = useState(1);
    const [selectedAnnotationIndex, setSelectedAnnotationIndex] = useState(null);

    //Rentering PDF
    // const [imageSrc, setImageSrc] = useState(null);
    // const loadPDF = async () => {
    //     try {
    //       // Access the PDF file from the public folder
    //       const pdfUrl = `http://localhost:3000/pdf/testPdf.pdf`;
    //       const pdf = await getDocument(pdfUrl).promise;

    //     // Load the first page
    //     const page = await pdf.getPage(2);
    //     const viewport = page.getViewport({ scale: 2 }); // Scale for better resolution

    //     const canvas = document.createElement("canvas");
    //     const context = canvas.getContext("2d");
    //     canvas.width = viewport.width;
    //     canvas.height = viewport.height;

    //     // Render the PDF page into the canvas
    //     await page.render({ canvasContext: context, viewport }).promise;

    //     // Convert the canvas content to a Data URL
    //     setImageSrc(canvas.toDataURL());
    //   } catch (error) {
    //     console.error("Error loading PDF:", error);
    //   }
    //   };

    // useEffect(() => {
    //     loadPDF();
    // }, []);

    const loadImages = async () => {
        try {
            const response = await axios.get(apiURL + `/projects/${window.globalVar1}/image_details`);
            const ImageDetails = response.data.map(item => ({
                imageId: item.img_id,
                imageUrl: item.location,
                fileName: item.img_name,
                markedLabels: ['l1', 'l2'],
                // markedLabels: item.labels,  // Assumes 'labels' are part of 'annotation'
                uploadDate: item.uploaded_date,
                thumbnail: `data:image/jpeg;base64,${item.thumbnail}`,  // Convert base64 to data URL
            }));
            // console.warn('updatedImages ==>', ImageDetails);
            setLoadedImages(ImageDetails);
        } catch (error) {
            console.error('Error fetching image details:', error);
        }
    }

    useEffect(() => {
        setTotalPages(loadedImages.length);
        const initialIndex = loadedImages.findIndex((image) => image.imageId === image_id);
        if (initialIndex !== -1) {
            setCurrentPage(initialIndex + 1);
        } else {
            setCurrentPage(1);
        }
    }, [loadedImages]);

    useEffect(() => {
        loadImages();
    }, []);



    const fetchAnnotations = async () => {
        try {
            const response = await axios.get(`${apiURL}/projects/${window.globalVar1}/images/${imageId}/meta/`);
            // console.log('response.data.all-->', response);
            // console.log('response.meta-->', response.data.meta.annotations);
            if (response.data.meta.annotations) {
                setAnnotations(response.data.meta.annotations);
                // console.log('DEFAULT LABELS:', response.data.meta.defaultLabels);
                setAnnotedLabels(response.data.meta.defaultLabels);
            } else {
                setAnnotations([]);
            }
        } catch (error) {
            console.error('Error fetching annotations:', error);
        }
    };

    useEffect(() => {
        fetchAnnotations();
    }, [apiURL, imageId]);


    const [selectedTab, setSelectedTab] = useState('field');
    const { setCollapsed } = useNavbar();
    setCollapsed(true);

    let tempAnnotation = defaultLabels.flatMap(item =>
        item.annotation_text.map(annotation => annotation.annotationDetails)
    );

    // const [annotations, setAnnotations] = useState(tempAnnotation);
    // useEffect(() => {

    //     const table_annotation = axios.get(apiURL + `/projects/${window.globalVar1}/images/${image_id}/meta`);
    //     if (table_annotation.get('annotations')) {
    //     setAnnotations(table_annotation.get('annotations'));
    //     }
    // }, []);

    useEffect(() => {
        setDefaultLabels(annotedLabels);
    }, [annotedLabels])

    // useEffect(() => {
    // console.log('defaultLabels:', defaultLabels);
    // console.log('tempAnnotation', tempAnnotation);
    // console.log('annotations', annotations);
    // console.log('annotedLabels', annotedLabels);
    // console.log('SELECTED LABEL', selectedLabel);
    // console.log('SELECTED BOX ID', selectedBoxId);
    // console.log('SELECTED imageId:', imageId);

    // }, [annotations, defaultLabels, selectedLabel, imageId])


    useEffect(() => {
        // console.log('maxAnnotations:', maxAnnotations);
        const maxData = Math.max(
            maxAnnotations,
            ...defaultLabels
                .filter((label) => label.extraction_type === 'Table')
                .map((label) => label.annotation_text.length)
        );

        // Update maxAnnotations to reflect the actual maximum annotations found
        setMaxAnnotations(maxData);

        // Update defaultLabels to ensure all have annotation_text arrays of length maxAnnotations
        const updatedLabels = defaultLabels.map((label) => {
            if (label.extraction_type === 'Table') {
                // If current annotation_text length is less than maxAnnotations, add empty objects
                const annotationCount = label.annotation_text.length;
                if (annotationCount < maxAnnotations) {
                    const newAnnotations = Array(maxAnnotations - annotationCount).fill({
                        bbox_ID: Math.random().toString(36).substring(2, 7),
                        annoted_Text: '',
                        TextBoxDetails: [],
                        annotationDetails: {}
                    });
                    return {
                        ...label,
                        annotation_text: [...label.annotation_text, ...newAnnotations]
                    };
                }
            }
            return label;
        });

        setDefaultLabels(updatedLabels);
        // console.log('annotations',annotations)
    }, [annotations, maxAnnotations]);



    // State to keep track of which cell is being edited
    const [editingCell, setEditingCell] = useState({ rowIndex: null, columnId: null });
    const [clickedCell, setClickedCell] = useState({ rowIndex: null, columnId: null });

    const [pageSize, setPageSize] = useState({
        width: (window.innerWidth) - (window.innerWidth * (35 / 100)),
        height: (window.innerHeight) - (window.innerHeight * (16 / 100))
    });

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onResize = () => {
        setPageSize({ width: window.innerWidth, height: window.innerHeight });
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        fetchImageDetails();
        return () => window.removeEventListener('resize', onResize);
    }, [imageId]);

    const fetchImageDetails = async () => {
        if (imageId) {
            try {
                const detailsResponse = await axios.get(apiURL + `/projects/${window.globalVar1}/single_image_details/${imageId}`);
                const details = detailsResponse.data.details;
                setLineImageDetails(details);

                if (details && details.ocr && details.ocr.ocr_data) {
                    setOcrDatas(details.ocr.ocr_data);
                }

                const imageResponse = await axios.get(apiURL + `/projects/${window.globalVar1}/image_file/${imageId}`, {
                    responseType: 'arraybuffer'
                });

                const contentType = imageResponse.headers['content-type'];
                if (contentType && contentType.includes('image/')) {
                    const blob = new Blob([imageResponse.data], { type: contentType });
                    setSelectedImage(URL.createObjectURL(blob));
                } else {
                    throw new Error(`Unexpected content type: ${contentType}`);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // image_id, rows
    useEffect(() => {
        // Reset selection when rows or annotations change
        setSelectedRow(null);
        setSelectedAnnotation(null);
    }, [defaultLabels, annotations]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowUp':
                    moveUp();
                    break;
                case 'ArrowDown':
                    moveDown();
                    break;
                case 'ArrowLeft':
                    moveLeft();
                    break;
                case 'ArrowRight':
                    moveRight();
                    break;
                default:
                    break;
            }
        };

        // window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('resize', onResize);
            // window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isDragging]);


    const onSelect = (id) => {
        selectAnnotation(id)
        // console.log("defaultLabels-->", defaultLabels);
        setSelectedBoxId(id);
        let selectedId = null;
        defaultLabels.forEach(row => {
            if (row.annotation_text) {
                row.annotation_text.forEach(annotation => {
                    if (annotation.bbox_ID === id) {
                        setSelectedLabel(row);
                        selectedId = row.id;
                    }
                });
            }
        });

        if (selectedRow) {
            setSelectedRow(selectedRow);
        }
    };

    const selectAnnotation = (bboxIdString) => {
        if (typeof bboxIdString === 'string' && bboxIdString.trim() !== '') {
            const bboxId = bboxIdString; // Use the string directly
            const annotation = annotations.find(item => item.id === bboxId);
            if (annotation) {
                setSelectedAnnotation(annotation);
            } else {
                console.log('Annotation not found for bbox_ID:', bboxId);
            }
        } else {
            console.log('Invalid bbox_ID String:', bboxIdString);
        }
    };


    const handleRightClick = (e) => {
        e.preventDefault();
        if (e.button === 2) {
            setRightClick(true);
        }
    };

    const onChange = (data) => {
        // Check if data is an array
        if (rightClick == false) {
            if (!Array.isArray(data)) {
                return;
            }
            if (selectedLabel) {
                // setAnnotationColor(selectedLabel.labelColor);
                const lastAnnotation = data[data.length - 1];
                const updatedAnnotations = data.map(annotation =>
                    annotation.id === lastAnnotation.id ? lastAnnotation : annotation
                );
                setAnnotations(updatedAnnotations);
            } else {
                setAnnotations(data);
            }
        }
    };


    const handleLabelClick = (label, index = null, box_id = '') => {
        setSelectedAnnotation(null);
        setSelectedLabel(label);
        setSelectedAnnotationIndex(index);
        setAnnotationColor(label.labelColor);
        if (box_id && box_id != null) {
            setSelectedBoxId(box_id);
            selectAnnotation(box_id);
        }
    };

    const handleEditAnnotation = (event, id) => {
        const newText = event.target.value;
        setDefaultLabels((prevLabels) =>
            prevLabels.map((label) =>
                label.id === id ? { ...label, annotation_text: newText } : label
            )
        );
    };

    const get_ocr_text = () => {
        let text = '';
        const { x, y, width, height } = annotations[annotations.length - 1].mark;
        const bbox = [x, y, x + width, y + height];

        let boxDetails = [];
        ocrDatas.forEach((item) => {
            const lineBox = item.bbox;
            if (
                lineBox[0] >= bbox[0] &&
                lineBox[1] >= bbox[1] &&
                lineBox[4] <= bbox[2] &&
                lineBox[5] <= bbox[3]
            ) {
                text = `${text} ${item.text}`;
                boxDetails.push(item);
            }
        });

        text = text.trim();
        return {
            text,
            boxDetails
        };
    };


    useEffect(() => {
        console.log('TABLE UPLATED');
        updateTable();
    }, [annotations]);

    const updateTable = () => {
        const annotationIds = new Set(annotations.map(annotation => annotation.id));
        const rowIds = new Set(defaultLabels.flatMap(label => label.annotation_text.map(annotation => annotation.bbox_ID)));

        const missingIds = [...rowIds].filter(id => !annotationIds.has(id));

        if (missingIds.length > 0) {
            // Find rows with missing bbox_IDs and set them to empty data
            const updatedLabels = defaultLabels.map(label => {
                if (label.extraction_type == 'Table') {
                    let updatedAnnotationText = [...label.annotation_text];

                    updatedAnnotationText = updatedAnnotationText.map(annotation => {
                        // If bbox_ID is missing, set the annotation data to empty
                        if (missingIds.includes(annotation.bbox_ID)) {
                            return {
                                bbox_ID: '',
                                annoted_Text: '',
                                TextBoxDetails: [],
                                annotationDetails: {}
                            };
                        }
                        return annotation;
                    });

                    // Ensure the total annotations match maxAnnotations
                    const annotationCount = updatedAnnotationText.length;
                    if (annotationCount < maxAnnotations) {
                        const newAnnotations = Array(maxAnnotations - annotationCount).fill({
                            bbox_ID: '',
                            annoted_Text: '',
                            TextBoxDetails: [],
                            annotationDetails: {}
                        });
                        updatedAnnotationText = [...updatedAnnotationText, ...newAnnotations];
                    }

                    return {
                        ...label,
                        annotation_text: updatedAnnotationText
                    };
                } else {
                    const updatedAnnotationText = label.annotation_text.filter(annotation => !missingIds.includes(annotation.bbox_ID));
                    return {
                        ...label,
                        annotation_text: updatedAnnotationText
                    };
                }
            });

            setDefaultLabels(updatedLabels);
        } else {
            // No missing IDs
            return [];
        }
    };

    // Function to push new annotation into the annotation_text array by id
    function addAnnotationDataById(list, id, annotation) {
        const item = list.find(item => item.id === id);
        if (item) {
            if (selectedAnnotationIndex !== null && selectedAnnotationIndex < item.annotation_text.length) {
                // Replace the annotation at the specified index
                item.annotation_text[selectedAnnotationIndex] = annotation;
            } else {
                // Add a new annotation if no valid selected index
                item.annotation_text.push(annotation);
            }
        } else {
            console.log(`Item with id ${id} not found.`);
        }
    }
    const updateAnnotationText = (id, newText, newBoxID, TextBoxDetails, lastAnnotation) => {
        const newAnnotation = {
            bbox_ID: newBoxID,
            annoted_Text: newText,
            TextBoxDetails: TextBoxDetails,
            annotationDetails: lastAnnotation
        };
        addAnnotationDataById(defaultLabels, id, newAnnotation);
        // console.warn(`DATA CHANGED:`, defaultLabels);
    };

    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);

    const handleMouseDown = (event) => {
        if (event.button === 2) { // Right mouse button
            event.preventDefault();
            setRightClick(true);
            setIsDragging(true);
            setDragStart({ x: event.clientX, y: event.clientY });
        }
    };

    const handleMouseMove = (event) => {
        event.preventDefault();
        if (!isDragging) return;

        const offsetX = event.clientX - dragStart.x;
        const offsetY = event.clientY - dragStart.y;

        setImagePosition((prevPosition) => ({
            x: prevPosition.x + offsetX,
            y: prevPosition.y + offsetY
        }));

        setDragStart({ x: event.clientX, y: event.clientY });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setRightClick(false);
    };

    const handleZoomIn = () => {
        setScale((prevScale) => Math.min(prevScale + 0.1, 3));
    };

    const handleZoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
    };


    //Image dragging
    useEffect(() => {
        const handleContextMenu = (event) => {
            if (event.button === 2) { // Right mouse button
                event.preventDefault();
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);

        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                if (event.key === '=') {
                    handleZoomIn();
                    event.preventDefault();
                } else if (event.key === '-') {
                    handleZoomOut();
                    event.preventDefault();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isDragging]);

    //checking if the bbox-id present in list
    const isBBoxIdInTable = (bboxId, labels) => {
        for (const label of labels) {
            for (const annotation of label.annotation_text) {
                if (annotation.bbox_ID === bboxId) {
                    return true;
                }
            }
        }
        return false;
    };
    const addToTable = () => {
        if (!annotations || annotations.length === 0) {
            return;
        }

        const lastAnnotation = annotations[annotations.length - 1];
        if (!lastAnnotation.mark) {
            return;
        }

        // if box id already in list ignore
        const bboxId = lastAnnotation.id;
        if (isBBoxIdInTable(bboxId, defaultLabels)) {
            return;
        }

        const { height, width, x, y } = lastAnnotation.mark;
        const BBID = lastAnnotation.id;
        setBoxId(BBID);

        const corners = [
            [parseFloat(x.toFixed(3)), parseFloat(y.toFixed(3))], // Top-left corner
            [parseFloat((x + width).toFixed(3)), parseFloat(y.toFixed(3))], // Top-right corner
            [parseFloat((x + width).toFixed(3)), parseFloat((y + height).toFixed(3))], // Bottom-right corner
            [parseFloat(x.toFixed(3)), parseFloat((y + height).toFixed(3))] // Bottom-left corner
        ];

        const result = get_ocr_text();
        const data = result.text;
        const boxDetails = result.boxDetails;

        if (data === "") {
            return;
        }
        const label_ID = selectedLabel.id;
        // console.log("SELECTED ID:", label_ID);

        updateAnnotationText(label_ID, data, BBID, boxDetails, lastAnnotation);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const getAnnotationStyle = () => {
        if (selectedLabel.id != 0) {
            return {
                padding: 2,
                fontSize: 12,
                fontColor: "black",
                // fontBackground: selectedLabel.color,
                fontFamily: "Roboto",
                borderRadius: 999,
                lineWidth: 2,
                shapeBackground: "hsla(120, 100%, 50%, 0.3)",
                shapeStrokeStyle: annotationColor,
                shadowBlur: 10,
                shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)",
                transformerBackground: "#5c7cfa",
                transformerSize: 10
            };
        } else {
            return {
                padding: 2,
                fontSize: 12,
                fontColor: "red",
                fontBackground: "#f8f9fa",
                fontFamily: "Roboto",
                borderRadius: 999,
                lineWidth: 2,
                shapeBackground: "hsla(120, 100%, 50%, 0.3)",
                shapeStrokeStyle: 'red',
                shadowBlur: 10,
                shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)",
                transformerBackground: "#5c7cfa",
                transformerSize: 10
            };
        }
    };

    // Control Buttons
    const zoomIn = () => {
        setZoomLevel(prevZoomLevel => Math.min(prevZoomLevel + 10, 200)); // Max zoom level 200%
    };

    const zoomOut = () => {
        setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel - 10, 10)); // Min zoom level 10%
    };

    const moveLeft = () => {
        setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x - 10 }));
    };

    const moveRight = () => {
        setPosition(prevPosition => ({ ...prevPosition, x: prevPosition.x + 10 }));
    };

    const moveUp = () => {
        setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y - 10 }));
    };

    const moveDown = () => {
        setPosition(prevPosition => ({ ...prevPosition, y: prevPosition.y + 10 }));
    };

    const scrollRef = useRef(null);

    // useEffect(() => {
    //     const scrollElement = scrollRef.current;
    //     if (scrollElement) {
    //         scrollElement.scrollTop = (scrollElement.scrollHeight - scrollElement.clientHeight) / 2;
    //         scrollElement.scrollLeft = (scrollElement.scrollWidth - scrollElement.clientWidth) / 2;
    //     }
    // }, []);


    const [zoom, setZoom] = useState(3);  // Default zoom level
    const submitClick = async () => {
        // console.log('defaultLabels', defaultLabels)
        // try {
        //     window.globalAnnotation = annotations;
        //     const payload = {
        //         annotations: {
        //             fieldLabels: defaultLabels
        //         }
        //     };

        //     const response = await axios.post(
        //         apiURL + `/projects/${window.globalVar1}/single_image_details/${image_id}`,
        //         payload,
        //         {
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             }
        //         }
        //     );

        //     if (response.status === 200) {
        //         console.log('Annotation updated successfully');
        //         // Optionally, you can update the UI or state here to reflect the successful update
        //     } else {
        //         console.error('Failed to update:', response.statusText);
        //         // Optionally, you can show an error message to the user
        //     }
        // } catch (error) {
        //     console.error('Error updating:', error.message);
        //     // Optionally, you can show an error message to the user
        // }

        const payload = {
            meta: {
                annotations: annotations,
                defaultLabels: defaultLabels,
                lastUpdated: new Date().toISOString(),
                version: "1.0"
            }
        };

        try {
            console.log('Sending payload:', payload); // Debug log

            const response = await axios.post(
                `${apiURL}/projects/${window.globalVar1}/images/${imageId}/meta`,
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    validateStatus: status => status === 200 // Only treat 200 as success
                }
            );

            console.log('Response:', response.data); // Debug log
            window.history.back();
            return response.data;

        }
        catch (error) {
            console.error('Error updating:', error.message);
            // Optionally, you can show an error message to the user
        }
    };


    return (
        <GridWrapper>
            <Box>
                <div style={{ display: "flex", marginTop: "5px", marginLeft: "15px", alignItems: "center" }}>
                    <button onClick={handlePrevious} disabled={currentPage === 1} style={{ marginRight: "3px", padding: "8px 6px" }}>
                        &lt;
                    </button>
                    <div style={{ border: "1px solid #ccc", padding: "5px 10px", borderRadius: "4px" }}>
                        {currentPage} / {totalPages}
                    </div>
                    <button onClick={handleNext} disabled={currentPage === totalPages} style={{ marginLeft: "3px", padding: "8px 6px" }}>
                        &gt;
                    </button>
                    <Divider orientation="vertical" />
                    <div style={{ position: "relative", maxWidth: "350px", marginRight:'5px' }}>
                        <TextField
                            variant="outlined"
                            size="small" 
                            placeholder="Search..."
                            value={searchValue}
                            onChange={handleSearchChange}
                            sx={{
                                marginLeft: "10px",
                                "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                        borderColor: "#ef798a",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#674f87",
                                    },
                                },
                            }}
                            style={{ marginLeft: "10px", width: "350px" }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {searchValue && filteredImages.length > 0 && (
                            <div
                                style={{
                                    position: "absolute",
                                    background: "#fff",
                                    border: "1px solid #ccc",
                                    borderTop: "none",
                                    zIndex: 1000,
                                    width: "350px", 
                                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                    borderRadius: "0 0 4px 4px",
                                    marginLeft: "10px"
                                }}
                            >
                                {filteredImages.map((image) => (
                                    <MenuItem key={image.imageId} onClick={() => handleSelectImage(image)}>
                                        {image.fileName}
                                    </MenuItem>
                                ))}
                            </div>
                        )}
                    </div>

                    <Button
                        variant="text"
                        startIcon={<DeleteIcon sx={{ color: '#000' }} />}
                        onClick={handleDelete}
                        sx={{ marginLeft: "10px", textTransform: "none", border: '1px solid grey', color: '#000' }}
                    >
                        Delete
                    </Button>
                </div>
            </Box>
            <Box
                component="main"
                sx={{ height: '93vh', width: '186vh', display: 'flex', paddingBlock: 1, position: "relative" }}
            >
                <Box
                    component="imageAnnotation"
                    sx={{ height: '92vh', width: '79%', marginInline: 1, bgcolor: '#ffffff' }}
                >
                    <div
                        style={{
                            height: '80px',
                            width: '99%',
                            margin: 'auto',
                            overflowX: 'auto',
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#888 transparent',
                        }}
                    >
                        <style>
                            {`
                                /* Webkit-based browsers (Chrome, Edge, Safari) */
                                div::-webkit-scrollbar {
                                    width: 3px; /* Vertical scrollbar width */
                                    height: 3px; /* Horizontal scrollbar height */
                                }
                                div::-webkit-scrollbar-thumb {
                                    background: #888; /* Scrollbar thumb color */
                                    border-radius: 2px;
                                }
                                div::-webkit-scrollbar-thumb:hover {
                                    background: #555; /* Scrollbar thumb hover color */
                                }
                                div::-webkit-scrollbar-track {
                                    background: transparent; /* Scrollbar track color */
                                }
                            `}
                        </style>
                        <table
                            border="1"
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                borderCollapse: 'collapse',
                                tableLayout: 'fixed',
                                margin: '3px 0px',
                                overflow: 'auto',
                                fontSize: '14px',
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '50px', fontSize: '14px' }}></th>
                                    <th style={{ textAlign: 'center', width: '60px', fontSize: '14px' }}>Index</th>
                                    {defaultLabels
                                        .filter((column) => column.extraction_type === 'Table')
                                        .map((column) => (
                                            <th
                                                key={column.id}
                                                style={{
                                                    textAlign: 'center',
                                                    width: '150px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        background: column.labelColor,
                                                        borderRadius: '50%',
                                                        marginRight: '2px',
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;
                                                </span>
                                                {column.labelName}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {[...Array(maxAnnotations)].map((_, rowIndex) => {
                                    const isRowEmpty = defaultLabels
                                        .filter((column) => column.extraction_type === 'Table')
                                        .every((column) => !column.annotation_text[rowIndex]?.annoted_Text);

                                    return (
                                        <tr key={rowIndex}>
                                            <td style={{ textAlign: 'center', width: '150px', fontSize: '14px' }}>
                                                <AddCircleIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setMaxAnnotations(maxAnnotations + 1)}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center', width: '150px', fontSize: '14px' }}>
                                                {rowIndex + 1}
                                            </td>
                                            {defaultLabels
                                                .filter((column) => column.extraction_type === 'Table')
                                                .map((column) => (
                                                    <td
                                                        key={`${column.id}-${rowIndex}`}
                                                        style={{
                                                            padding: '1px',
                                                            width: '150px',
                                                            cursor: 'pointer',
                                                            fontSize: '14px',
                                                            backgroundColor:
                                                                column.annotation_text[rowIndex]?.bbox_ID !== ''
                                                                    ? selectedBoxId === column.annotation_text[rowIndex]?.bbox_ID
                                                                        ? '#ef798a'
                                                                        : 'transparent'
                                                                    : 'transparent',
                                                            border: '1px solid black',
                                                        }}
                                                        onClick={() =>
                                                            column.annotation_text[rowIndex]
                                                                ? handleLabelClick(column, rowIndex, column.annotation_text[rowIndex].bbox_ID)
                                                                : handleLabelClick(column, rowIndex)
                                                        }
                                                    >
                                                        {isRowEmpty ? (
                                                            <TextField
                                                                style={{ margin: '0px', width: '100%', fontSize: '10px' }}
                                                                value=""
                                                                placeholder="No Annotations"
                                                                InputProps={{
                                                                    style: {
                                                                        fontSize: '14px',
                                                                    },
                                                                }}
                                                            />
                                                        ) : (
                                                            <TextField
                                                                style={{ margin: '0px', width: '100%', fontSize: '10px' }}
                                                                value={column.annotation_text[rowIndex]?.annoted_Text || ''}
                                                                placeholder="No Annotations"
                                                                InputProps={{
                                                                    style: {
                                                                        fontSize: '14px',
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>


                    <div
                        // ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        style={{ height: '81%', width: '99%', overflow: 'hidden', position: 'relative', backgroundColor: "#ffffff", borderRadius: "10px", border: "5px solid #d8d8d8", margin: 'auto' }}
                        onWheel={(e) => e.preventDefault()}>
                        <div onMouseUp={addToTable} style={{ transform: `scale(${zoomLevel / 100}) translate(${position.x}px, ${position.y}px)`, transformOrigin: 'top left', minHeight: '90%', minWidth: '90%', display: 'inline-block' }}>

                            <div id='baseimage-DIV'
                                style={{
                                    position: 'absolute',
                                    top: imagePosition.y,
                                    left: imagePosition.x,
                                    transform: `scale(${scale})`,
                                    transformOrigin: 'top left',
                                    cursor: isDragging ? 'grabbing' : 'grab',
                                }}>
                                <ReactPictureAnnotation
                                    image={selectedImage}
                                    // image={imageSrc}
                                    onSelect={onSelect}
                                    onChange={onChange}
                                    width={990}
                                    height={800}
                                    annotationData={annotations}
                                    annotationStyle={getAnnotationStyle()}
                                    selectedId={selectedAnnotation ? selectedAnnotation.id : null}
                                    onContextMenu={handleRightClick}
                                    zoom={zoom}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" size='small' onClick={zoomOut}><ZoomOutIcon /></Button>
                        <span style={{ margin: '0 10px' }}>{zoomLevel}%</span>
                        <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" size='small' onClick={zoomIn}><ZoomInIcon /></Button> */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }}
                                variant="contained"
                                size="small"
                                onClick={zoomOut}
                            >
                                <ZoomOutIcon />
                            </Button>

                            <Slider
                                value={zoomLevel}
                                onChange={handleZoomChange}
                                aria-labelledby="zoom-slider"
                                min={10}
                                max={200}
                                step={10}
                                style={{ margin: '0 10px', width: 180 }}
                                sx={{
                                    color: '#674f87', // Change the slider color to yellow
                                    '& .MuiSlider-thumb': { backgroundColor: '#674f87' },
                                    '& .MuiSlider-rail': { backgroundColor: '#ef798a' },
                                }}
                            />

                            <Button
                                sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }}
                                variant="contained"
                                size="small"
                                onClick={zoomIn}
                            >
                                <ZoomInIcon />
                            </Button>

                            {/* <span style={{ marginLeft: '10px' }}>{zoomLevel}%</span> */}
                        </div>

                        <Button sx={{ marginRight: "5px", marginLeft: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" size='small' onClick={moveLeft}><KeyboardArrowLeft /></Button>
                        <Button sx={{ marginRight: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" size='small' onClick={moveUp}><KeyboardArrowUp /></Button>
                        <Button sx={{ marginRight: "5px", background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" size='small' onClick={moveDown}><KeyboardArrowDown /></Button>
                        <Button sx={{ background: "linear-gradient(to right, #ef798a, #674f87)" }} variant="contained" size='small' onClick={moveRight}><KeyboardArrowRight /></Button>
                    </div>
                </Box>
                <Box component='LabelView' sx={{ bgcolor: '#ffffff', width: '21%', height: '92vh', overflow: 'auto' }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab style={{ fontWeight: 'bold' }} label="Fields" {...a11yProps(0)} />
                                {/* <Tab style={{ fontWeight: 'bold' }} label="Tables" {...a11yProps(1)} /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel onClick={() => setSelectedTab('field')} value={value} index={0}>
                            <Typography>
                                <h4> LABELS </h4>
                            </Typography>
                            <Table sx={{ width: '100%', overflowX: 'hidden', fontSize: '14px' }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <h4 style={{ fontSize: '14px', margin: 0 }}>Label Name</h4>
                                        </TableCell>
                                        <TableCell align="right">
                                            <h4 style={{ fontSize: '14px', margin: 0 }}>Annotations</h4>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {defaultLabels.filter(column => column.extraction_type === 'Field').map((row) => (
                                        row.annotation_text.length > 0 ? (
                                            row.annotation_text.map((annotation, index) => (
                                                <TableRow
                                                    key={`${row.id}-${index}`}
                                                    onClick={() => handleLabelClick(row, index, annotation.bbox_ID)}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        backgroundColor: selectedBoxId === annotation.bbox_ID ? '#ef798a' : 'transparent',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: '#ef798a',
                                                        },
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <span
                                                            style={{
                                                                borderLeft: '6px solid',
                                                                borderColor: row.labelColor,
                                                                padding: '4px',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            {row.labelName}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <div style={{ textAlign: 'left', fontSize: '14px' }}>
                                                            <TextField
                                                                style={{ margin: '0px', width: '100%' }}
                                                                InputProps={{
                                                                    style: { fontSize: '14px' },
                                                                }}
                                                                value={annotation.annoted_Text}
                                                                onChange={(event) => handleEditAnnotation(event, row.id, index)}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow
                                                key={row.id}
                                                onClick={() => handleLabelClick(row)}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    backgroundColor: selectedLabel.id === row.id ? '#ef798a' : 'transparent',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: '#ef798a',
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <span
                                                        style={{
                                                            borderLeft: '6px solid',
                                                            borderColor: row.labelColor,
                                                            padding: '4px',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {row.labelName}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div style={{ textAlign: 'left', fontSize: '14px' }}>
                                                        <TextField
                                                            style={{ margin: '0px', width: '100%' }}
                                                            InputProps={{
                                                                style: { fontSize: '14px' },
                                                            }}
                                                            value=""
                                                            placeholder="No Annotation"
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ))}
                                </TableBody>
                            </Table>

                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button onClick={submitClick} sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)', color: '#ffffff' }}>
                                    Submit
                                </Button>
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </Box>
            </Box>
        </GridWrapper >
    );
}
