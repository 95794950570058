import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { topmenuStyles } from './styles';
import Ai_logo from '../images/AI_white.svg'
import { Height } from '@mui/icons-material';
import logo from '../images/qd_white.png';
import ai_2 from '../images/ai_ch.png'
import Home from '../../pages/Home/Home';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import {CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import { useAuth } from '../AuthContext/AuthContext';
export default function TopMenu() {
  const { logout } = useAuth();
  console.log(' window log out--------------------------',window.localStorage.getItem('isLoggedIn'))
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Added loading state
  const [anchorEl, setAnchorEl] =useState(null);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleLogout = async (e) => {
      setLoading(true);
      console.log('log out--------------------------')
      console.log(' window log out--------------------------',window.localStorage.getItem('isLoggedIn'))
      
      logout();
      navigate('/');  
      setLoading(false);
  };
  return (
    <AppBar position="fixed" sx={ topmenuStyles.top_menu }>
        <Toolbar>
          <div onClick={() => navigate('home')}>
            <Typography  noWrap component="img" src={Ai_logo} sx={{marginRight:'auto', width: '10%'}}>
        
            </Typography>
          </div>
          <div style={{flexGrow:1}}/>
          <div>
                    {/* <Tooltip title={user ? `${user.firstName} ${user.lastName}` : 'Guest'}> */}
                        <Avatar sx={{
                            border: '2px solid #f1f1f1',
                            cursor: 'pointer',
                            bgcolor: '#9D3C5D',
                            color: '#f1f1f1',
                            '&:hover': {bgcolor: '#432F60'},
                        }} onClick={handleClick}>
                            <PersonIcon/>
                        </Avatar>
                    {/* </Tooltip> */}
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Button variant='contained' color='secondary' size='small' onClick={handleLogout}
                                sx={{
                                    marginTop: '8px',
                                    width: '100%',
                                    borderTop: '1px solid #e0e0e0',
                                    paddingTop: '8px',
                                    overflow: 'hidden',
                                    position: 'relative'
                                }} disabled={loading}
                                endIcon={loading ? (<CircularProgress
                                    size={24}
                                    color="secondary"
                                    sx={{
                                        position: 'absolute',
                                        top: '15%',
                                        left: '40%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                />) : null}
                        >
                            {loading ? '...' : 'logout'}
                        </Button>
                    </Popover>
                </div>
        </Toolbar>

        </AppBar>

  );
}

