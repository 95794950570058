import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Modal, IconButton, InputAdornment, TextField } from '@mui/material';
import { Facebook, Google, LinkedIn, Visibility, VisibilityOff } from '@mui/icons-material'; // Import icons
import './login.css';
import image1 from '../images/007896060_00008.jpg';
import LoginImage from '../images/ai_ch.png';
import axios from 'axios';

import { useAuth } from '../../components/AuthContext/AuthContext';

const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const apiURL = process.env.REACT_APP_BACKEND_API_URL;
    // const handleLogin = () => {
    //     setShowModal(true);

    //     window.localStorage.setItem('isLoggedIn', true); // Store login state (temporary example)
    //     navigate('/');
    // };

    const handleLogin = async (e) => {
        e.preventDefault();  // Prevent form from reloading page

        try {
            const response = await axios.post(apiURL + '/auth/login', {
                username,
                password
            });

            // If login is successful, store token and navigate to home
            if (response.status === 200) {
                console.log('in--------------------------')
                login(username,password);
                // const token = response.data.token;
                // console.log(token)
                // window.localStorage.setItem('token', token);  // Store token
                window.localStorage.setItem('isLoggedIn', true);  // Store login state (example)
                navigate('/model-training');  // Redirect to home page or any route you prefer
            }
        } catch (err) {
            // Handle errors from the backend response
            if (err.response) {
                setError(err.response.data.detail);
            } else {
                setError("An unexpected error occurred");
            }
        }
    };
    const handleCloseLogin = () => {
        setShowModal(true);

    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    return (
        <>
            {/* Render the blur effect if showModal is true */}
            {showModal && <div className="blur-background" onClick={handleCloseLogin}></div>}
            
            {/* Render the modal if showModal is true */}
            <Modal open={showModal} onClose={handleCloseLogin}>
                <div className="login_modal">
                    <div className="login_left-half">
                        <img src={LoginImage} alt='logo' 
                                                            style={{ width: '25%', height: '10%' }} ></img>
                        <div className='content'>
                            <h1>Welcome back</h1>

<form onSubmit={handleLogin}>
                                <div>
                                    <TextField
                                        type="text"
                                        placeholder="Username"
                                        id="username"
                                        name="username"
                                        style={{ backgroundColor: 'white', width: '100%', marginBottom: '15px' }}
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                                <div className="password-input-container">
                                    <TextField
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Password"
                                        id="password"
                                        name="password"
                                        style={{ backgroundColor: 'white', width: '100%', marginBottom: '15px' }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={togglePasswordVisibility}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                {error && <p className="error-message">{error}</p>}
                                <div className='forgot-pass'>
                                    <p>Forgot your password? <span>Click here</span></p>
                                </div>
                                <Button variant="contained" type="submit" className='loginbtn'>
                                    SIGN IN
                                </Button>
                            </form>
                        </div>
                    </div>
                    <div className="login_right-half">
                        <div className='heading'>
                            <p>Intelligent data capture made easy.<br/>Extract key info from documents with AI.</p>
                        </div>
                        <h1>Create Account</h1>
                        <div className='aggriment'>
                            <p>By clicking Sign UP, you agree to our<br/>terms, Privacy Policy and Cookies</p>
                        </div>
                        <Button variant="contained" onClick={handleLogin}>
                            SIGN UP
                        </Button>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default Login;
