import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import { Outlet } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom'; 
import TopMenu from './components/TopMenu/TopMenu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Cbot from './components/chatBAr/chat-bot';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import { useAuth } from './components/AuthContext/AuthContext';

function App() {
  const { isLoggedIn } = useAuth();
  const [title, setTitle] = useState(null);
  const location = useLocation();
  window.globalVariable = 'someValue';
  var [ extractionType  , setExtractionType] = useState('None');
  useEffect(() => {
    const parsedTitle = location.pathname.replace(/\W/g, ' ');
    setTitle(parsedTitle);
  }, [location]);

  return (
    <div>
    
      {isLoggedIn ? ( 
          
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <TopMenu />
              <Navbar />
              <Cbot/>
            <Box component="main" sx={{ 
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                paddingLeft: 10,
                height: 'calc(100vh - 50px)',
                width: 'calc(100vw - 10px)',}}>
              <Toolbar />
              <Outlet />
              {/* <Home/> */}
            </Box>
            </Box>
      ):(<Login />)}
      </div>

    
  );
}

export default App;