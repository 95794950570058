import React, { useEffect, useState } from "react";
import {
    Tabs,
    Tab,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Checkbox,
    styled,
    IconButton
} from "@mui/material";
import { Search } from "@mui/icons-material";

const StyledTabs = styled(Tabs)({
    borderBottom: "1px solid #e0e0e0",
    minHeight: "40px",
    ".MuiTab-root": {
        fontSize: "14px",
        textTransform: "none",
        minWidth: 0,
        padding: "0 16px",
        borderRadius: "10px 10px 0px 0px",
        "&:hover": {
            color: "#ef798a",
        },
    },
    ".Mui-selected": {
        color: "#ef798a",
        backgroundColor: '#f1f1f1',
        borderBottom: "2px solid #ef798a",
        borderRadius: "10px 10px 0px 0px"
    },
});

const StyledTable = styled(TableContainer)(({ theme }) => ({
    
    marginTop: theme.spacing(2),
    border: "1px solid #e0e0e0",
    height: '220px', // Set fixed height
    overflowY: 'auto', // Enable vertical scrolling
    ".MuiTableCell-root": {
        padding: "8px 16px",
        fontSize: "14px",
        borderBottom: "1px solid #e0e0e0",
    },
    ".MuiCheckbox-root": {
        padding: "0 16px",
    },
    ".MuiTableRow-root:hover": {
        backgroundColor: "#f5f5f5",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default function TrainingTable( {logdata}) {
    useEffect(() => {
        setLogData(logdata);  
    }, [logdata]);

    const [logData, setLogData] = useState(logdata);

    const [selectedTab, setSelectedTab] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredLogs = logData.filter(
        (log) =>
            log.timestamp.toLowerCase().includes(searchTerm) ||
            log.dataset.toLowerCase().includes(searchTerm) ||
            log.message.toLowerCase().includes(searchTerm)
    );

    return (
        <Box sx={{ width: "100%", padding: 1, boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)', borderRadius: 5 }}>
            <StyledTabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Logs" />
                {/* <Tab label="Tab 2" />
                <Tab label="Tab 3" />
                <Tab label="Tab 4" /> */}
            </StyledTabs>

            <TabPanel value={selectedTab} index={0}>
                <Box sx={{ paddingTop: 2 }}>
                    <TextField
                        placeholder="Search for log entries..."
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            style: {
                                borderRadius: "25px",
                                height: "40px",
                            },
                            startAdornment: (
                                <IconButton disableRipple>
                                    <Search />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#ef798a',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#ef798a',
                              },
                            },
                          }}
                    />
                </Box>
                <StyledTable component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{maxWidth:'80px',fontWeight:'bold'}}>Timestamp</TableCell>
                                <TableCell sx={{maxWidth:'100px',fontWeight:'bold'}}>Event Dataset</TableCell>
                                <TableCell sx={{maxWidth:'150px',fontWeight:'bold'}}>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredLogs.map((log, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{maxWidth:'80px'}}>{log.timestamp}</TableCell>
                                    <TableCell sx={{maxWidth:'100px'}}>{log.dataset}</TableCell>
                                    <TableCell sx={{maxWidth:'150px', overflow:'hidden'}}>{log.message['log']}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTable>
            </TabPanel>

            {/* <TabPanel value={selectedTab} index={1}>
                <p>Content for tab 2</p>
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
                <p>Content for tab 3</p>
            </TabPanel>

            <TabPanel value={selectedTab} index={3}>
                <p>Content for tab 4</p>
            </TabPanel> */}
        </Box>
    );
}
