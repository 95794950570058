import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Typography } from '@mui/material';

class LineChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [{
                name: "Accuracy",
                data: props.chartData
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Model Accuracy vs Epoch',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                colors: ['#EA788A'],
                xaxis: {
                    categories: ['5', '10', '15', '20', '25', '30', '35', '40', '45'],
                }
            },
            prevChartData: props.chartData // Store initial chartData
        };
    }

    componentDidUpdate() {
        const { chartData } = this.props;
        const { prevChartData } = this.state;

        // Check if chartData prop has changed
        if (prevChartData !== chartData) {
            this.setState({
                series: [{
                    name: "Accuracy",
                    data: chartData
                }],
                prevChartData: chartData // Update prevChartData for future comparisons
            });
        }
    }

    render() {
        return (
            <Box sx={{
                padding: 2,
                width: 750,
                height: 400,
                borderRadius: 5, 
                boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
            }}
            >
                {/* <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    Product Trends by Month
                </Typography> */}
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={300}
                    colors ='red'
                />
            </Box>
        );
    }
}

export default LineChart;
