import React, { useState } from 'react';
import { Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Radio } from '@mui/material';
import axios from 'axios';
import { RocketLaunch } from '@mui/icons-material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { keyframes } from '@mui/system';

const scaleIn = keyframes`
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const apiURL = process.env.REACT_APP_BACKEND_API_URL;

const Deploy = () => {
    const currentDate = new Date().toLocaleDateString();
    const [versionData, setVersionData] = useState([
        {
            id: 1,
            name: 'Version 1',
            data: {
                execution_run: { total: 110, passed: 90, failed: 10 },
                status: true,
                start_time: '16:08 PM, 10-12-2024',
                end_time: '17:08 PM, 10-12-2024',
                progress: '30%',
                accuracy: '99.2',
                created:currentDate,
                last_modified:currentDate
                

            },
        },
        {
            id: 2,
            name: 'Version 2',
            data: {
                execution_run: { total: 100, passed: 40, failed: 60 },
                status: false,
                start_time: '18:08 PM, 10-12-2024',
                end_time: '19:08 PM, 10-12-2024',
                accuracy: '95.6',
                created:currentDate,
                last_modified:currentDate
            },
        },
    ]);

    const [selectedCellData, setSelectedCellData] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleCellClick = (data, id) => {
        setSelectedCellData(data);
        setSelectedRowId(id); 
    };

    const handleDeploy = async () => {
        if (!selectedCellData) return;
        setIsLoading(true);

        const ai_text = window.globalVar1;
        try {
            const response = await axios.post(apiURL + `/api/ai-text`, {
                text: ai_text,
                model_name: 'Test'
            });
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error sending AI text:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Typography variant="h4" color="inherit" sx={{ mb: 2 }}>
                Model Versions
            </Typography>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Select</TableCell>
                        <TableCell>Version Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>Last Modified</TableCell>
                        <TableCell>Accuracy</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {versionData.map((version) => (
                        <TableRow
                            key={version.id}
                            hover
                            onClick={() => handleCellClick(version.data, version.id)}
                            sx={{
                                '&:hover': { backgroundColor: '#f0f0f0', cursor: 'pointer' },
                                backgroundColor: selectedRowId === version.id ? '#e0e0e0' : 'white',
                            }}
                        >
                            <TableCell>
                                <Radio
                                    checked={selectedRowId === version.id}
                                    onChange={() => handleCellClick(version.data, version.id)}
                                    checkedIcon={
                                        <CheckCircleIcon
                                            sx={{
                                                animation: `${scaleIn} 0.3s ease-in-out`,
                                            }}
                                        />
                                    }
                                    icon={<RadioButtonUncheckedIcon />}
                                    sx={{
                                        color: '#674f87',
                                        '&.Mui-checked': {
                                            color: '#674f87',
                                        },
                                    }}
                                />
                            </TableCell>
                            <TableCell>{version.name}</TableCell>
                            <TableCell>{version.data.status ? 'Active' : 'Inactive'}</TableCell>
                            <TableCell>{version.data.created}</TableCell>
                            <TableCell>{version.data.last_modified}</TableCell>
                            <TableCell>{version.data.accuracy}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                    variant="contained"
                    onClick={handleDeploy}
                    startIcon={<RocketLaunch />}
                    sx={{
                        background: 'linear-gradient(to right, #ef798a, #674f87)',
                    }}
                    disabled={!selectedCellData || isLoading}
                >
                    Deploy
                </Button>
            </Box>
        </Box>
    );
};

export default Deploy;