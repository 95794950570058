import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box, List, TextField, ListItem, Autocomplete, Divider, FormControl, InputLabel, Checkbox,
    ListItemText, MenuItem, Select, Typography, Paper, InputBase, IconButton, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Collapse
} from '@mui/material';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import TrainingSetup from './TrainingSetup';
import { Button } from '@mui/material';
import LogStream from './LogStream';
import Deploy from './Deploy';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useNavbar } from '../Navbar/NavbarContext';

const apiURL = process.env.REACT_APP_BACKEND_API_URL;

// State variables to manage different aspects of the component
const ProjectSelection = () => {
    const [projectid, setProjectid] = useState('No');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [projectLabels, setProjectLabels] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [selectedId, setSelectedId] = useState(1);
    const [newCondition, setNewCondition] = useState('');
    const [editedCondition, setEditedCondition] = useState('');
    const selectedProjectID = window.globalVar1;
    const { setCollapsed } = useNavbar();
    setCollapsed(false);

    // Static data for extraction rules, used as an initial state
    const extraction = [
        { name: "Name", id: 1, project_id: 2, meta: { ExtractionRules: ["The word will be alpha", "DOB in numbers"] } },
        { name: "Age", id: 2, project_id: 5, meta: { ExtractionRules: ["The word will be alpha", "Rule2"] } }
    ];

    let dummySuggestions = ['time', 'date', 'rules', 'casual rules', 'rule5', 'monthly rule'];

    // State to manage the extraction rules
    const [extractionRules, setExtractionRules] = useState(extraction);

    const [labelDetails, setLabelDetails] = useState(null);
    const [selectedRules, setSelectedRules] = useState(null);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);

    const [newRule, setNewRule] = useState("");
    const [addRuleRow, setAddRuleRow] = useState(null);
    const [expandedRow, setExpandedRow] = useState([]);
    const [expandedAddRuleRow, setExpandedAddRuleRow] = useState([]);

    const [allExpanded, setAllExpanded] = useState(false);

    const handleExpandAllClick = () => {
        if (allExpanded) {
            // Collapse all rows
            setExpandedRow([]);
        } else {
            // Expand rows with rules > 1
            const rowsToExpand = labelDetails
                .filter((label) => getRulesByLabelId(label.id).length > 1)
                .map((label) => label.id);
            setExpandedRow(rowsToExpand);
        }
        setAllExpanded(!allExpanded);
    };



    const handleAddRuleClick = (labelId) => {
        handleExpandClick(labelId);
        setAddRuleRow(labelId);
        setExpandedAddRuleRow(labelId);
    };

    const handleCheckboxChange = (labelId) => {
        setSelectedLabels((prevSelected) => {
            if (prevSelected.includes(labelId)) {
                // If the label is already selected, remove it
                return prevSelected.filter((id) => id !== labelId);
            } else {
                // If the label is not selected, add it
                return [...prevSelected, labelId];
            }
        });
    };


    const handleExpandClick = (id) => {
        setExpandedRow((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };


    const getRulesByLabelId = (labelId) => {
        return selectedRules.filter((rule) => rule.label_id === Number(labelId));
    };
    const [labelIds, setLabelIds] = useState([]);
    useEffect(() => {
        if (labelDetails != null) {
            const getAllLabelIds = () => {
                const ids = labelDetails.map((label) => label.id);
                setLabelIds(ids);
            };
            getAllLabelIds();
        }

    }, [labelDetails])
    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${apiURL}/projects/${projectid}/`);
                console.log("Project retrieved successfully:", response.data);
                setProjectData(response.data);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error fetching project data');
            }
        };

        fetchProjectData();
    }, [projectid]);

    const handleSaveRule = async (labelId) => {
        if (newRule.trim() === '') return;

        try {
            const data = { rule: newRule };
            const headers = { "Content-Type": "application/json" };

            const response = await axios.post(`${apiURL}/labels/${labelId}/rules/`, data, { headers });
            console.log("Rule added successfully:", response.data);

            setNewRule("");
            setAddRuleRow(null);
            fetchRules();
        } catch (err) {
            setError(err.response ? err.response.data.detail : 'Error adding Rule');
        }
    };

    const handleInputChange = (event, newValue) => {
        setNewCondition(newValue);

        if (newValue.trim() !== '') {
            const similarItems = dummySuggestions
                .filter((item) => item.toLowerCase().includes(newValue.toLowerCase()))
                .slice(0, 5);

            setFilteredSuggestions(similarItems);
        } else {
            setFilteredSuggestions([]);
        }
    };

    useEffect(() => {
        console.warn("LABELS:", labelDetails);
        console.warn("SELECTED Rules:", selectedRules);
        console.warn("SELECTED IDs:", labelIds);
        console.warn("SELECTED Labels:", selectedLabels);
    }, [labelDetails, labelIds, selectedRules, selectedLabels]);

    useEffect(() => {
        fetchRules();
    }, [labelIds]);

    const fetchLabels = async (extractionType) => {
        try {
            const response = await axios.get(apiURL + `/projects/${window.globalVar1}/labels_format_2/`);
            setLabelDetails(response.data);
        } catch (err) {
            setError(err.response ? err.response.data.detail : 'Error fetching projects');
        }
    };

    const fetchRules = async (extractionType) => {
        try {
            // Fetch rules for each label in labelId and accumulate the results
            const responses = await Promise.all(
                labelIds.map(async (labelId) => {
                    const response = await axios.get(`${apiURL}/labels/${labelId}/rules/`);
                    return response.data;
                })
            );

            // Flatten the responses array if needed and set it to selectedRules
            const allRules = responses.flat(); // .flat() flattens arrays in case each response contains a list of rules
            console.log("Received Rules:", allRules);
            setSelectedRules(allRules);
        } catch (err) {
            setError(err.response ? err.response.data.detail : 'Error fetching projects');
        }
    };


    const addRule = async (extractionType) => {
        if (newCondition.trim() === '') return;
        if (selectedLabels != '') {
            try {
                const data = {
                    "rule": newCondition
                };
                const headers = {
                    "Content-Type": "application/json"
                };
                // Loop through each label in labelId and make a POST request
                const responses = await Promise.all(
                    selectedLabels.map(async (labelId) => {
                        const response = await axios.post(`${apiURL}/labels/${labelId}/rules/`, data, { headers });
                        return response.data;
                    })
                );
                console.log("Rule added successfully:", responses);
                fetchRules();
                setNewCondition('');
                setFilteredSuggestions([]);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error adding Rules');
            }
        } else {
            alert("Select a Label");
        }
    };
    const handleDeleteRule = async (id) => {
        try {
            await axios.delete(`${apiURL}/rules/${id}`);
            console.log("Successfully deleted Rule with ID:", id);

            // Remove the deleted rule from selectedRules state
            setSelectedRules((prevRules) => prevRules.filter((rule) => rule.id !== id));
        } catch (err) {
            setError(err.response ? err.response.data.detail : 'Error deleting rule');
        }
    };



    useEffect(() => {
        fetchLabels();
    }, []);

    // Fetches the project ID when the component mounts
    useEffect(() => {
        setProjectid(window.globalVar1);
    }, []);

    // Fetches project data and labels when the component mounts or the project ID changes
    useEffect(() => {
        const fetchProjects = async (extractionType) => {
            try {
                const response = await axios.get(apiURL + `/projects/${extractionType}/`);
                setProjectData(response.data);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error fetching projects');
            } finally {
                setLoading(false);
            }
        };

        const fetchProjectLabels = async (extractionType) => {
            try {
                const response = await axios.get(apiURL + `/projects/${extractionType}/labels/`);
                setProjectLabels(response.data);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error fetching project labels');
            } finally {
                setLoading(false);
            }
        };

        fetchProjects(window.globalVar1);
        fetchProjectLabels(window.globalVar1);
    }, []);

    // Static list for rendering selection cards
    const selectionList = [
        { id: 1, header: 'Train Model', content: '', icon: ModelTrainingOutlinedIcon },
        { id: 2, header: 'Annotate Dataset', content: '', icon: FormatShapesOutlinedIcon },
        { id: 3, header: 'Add Extraction Rules', content: '', icon: RuleFolderOutlinedIcon },
        { id: 4, header: 'Deploy', content: '', icon: EditNoteOutlinedIcon },
    ];

    // Handles the card click event and updates the selected ID
    const handleCardClick = (id) => {
        console.log("DETAILS:", projectData);
        setSelectedId(id);
    };

    const [base_labels, setLabels] = React.useState([]);
    const create_agent = () => {
        console.log("DETAILS: o**************");
        const fetchCustomProjects = async (project_id) => {
            try {
                const response = await axios.get(apiURL + `/projects/${project_id}/labels/`);
                const projectsData = response.data.map(project => ({
                    ...project
                }));
                setLabels(projectsData);
                console.log('projectsData---', projectsData);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error fetching projects');
            } finally {
                setLoading(false);
            }

        };
        fetchCustomProjects(projectid);
        console.log('labels---', base_labels);
        // projectid
    };

    const updateRules = async () => {
        try {
            const response = await axios.post(`projects/`,
                extractionRules,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                console.log('Rules updated successfully');
                // Optionally, you can update the UI or state here to reflect the successful update
            } else {
                console.error('Failed to update rules:', response.statusText);
                // Optionally, you can show an error message to the user
            }
        } catch (error) {
            console.error('Error updating:', error.message);
        }
    }

    return (
        <Box>
            <h1>Project Selection ({projectData.name}) </h1>
            <Divider />
            <Divider />
            <Box sx={{ display: 'flex', gap: 4, py: 3 }}>
            {selectionList.map((list) => {
                const IconComponent = list.icon;
                const isSelected = selectedId === list.id;

                return (
                    <Box
                                    key={list.id}
                                    onClick={() => handleCardClick(list.id)}
                                    sx={{
                                        borderRadius: '12px',
                                        padding: '16px',
                                        width: 320,
                                        height: 90,
                                        display: 'flex',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        border: isSelected 
                                            ? '2px solid #674f87' 
                                            : '2px solid transparent',
                                        backgroundColor: isSelected 
                                            ? 'rgba(103, 79, 135, 0.05)' 
                                            : '#ffffff',
                                        boxShadow: isSelected
                                            ? '0 8px 15px rgba(103, 79, 135, 0.15)'
                                            : '0 4px 10px rgba(0,0,0,0.08)',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: '0 8px 15px rgba(103, 79, 135, 0.2)',
                                        },
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={2}
                                        width="100%"
                                    >
                                        <Box
                                            sx={{
                                                width: 56,
                                                height: 56,
                                                background: isSelected 
                                                    ? 'linear-gradient(135deg, #ef798a 0%, #674f87 100%)' 
                                                    : 'rgba(103, 79, 135, 0.08)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '8px',
                                                transition: 'all 0.3s ease',
                                            }}
                                        >
                                            <IconComponent
                                                sx={{
                                                    color: isSelected ? '#ffffff' : '#674f87',
                                                    fontSize: '28px',
                                                    transition: 'color 0.3s ease',
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                flex: 1,
                                                borderRadius: '8px',
                                                padding: '10px 14px',
                                                background: 'transparent',
                                                transition: 'all 0.3s ease',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    color: isSelected ? '#674f87' : '#333',
                                                    fontWeight: isSelected ? 700 : 600,
                                                    fontSize: '1rem',
                                                    lineHeight: 1.3,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    transition: 'all 0.3s ease',
                                                }}
                                            >
                                                {list.header}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: isSelected ? '#674f87' : '#666',
                                                    opacity: isSelected ? 1 : 0.7,
                                                    fontSize: '0.8rem',
                                                    transition: 'all 0.3s ease',
                                                }}
                                            >
                                                {list.content}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>


            {selectedId === 3 ? (
                <Box id="dropdown">
                    <h1 >Custom Your Extraction Rules</h1>
                    <Divider />
                    <Box sx={{ display: 'flex', marginLeft: 1, marginTop: '20px' }}>
                        <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
                            <Box sx={{ maxHeight: 500, overflowY: "auto", width: "100%" }}>
                                <Table sx={{ minWidth: 350, maxWidth: 1000 }} size="small" aria-label="simple table">
                                    <TableHead sx={{ position: "sticky", top: 0, backgroundColor: "#f1f1f1", zIndex: 1, lineHeight: "30px" }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: "bold", color: "#674f87" }}>SELECT</TableCell>
                                            <TableCell sx={{ fontWeight: "bold", color: "#674f87" }}>LABEL NAME</TableCell>
                                            <TableCell sx={{ fontWeight: "bold", color: "#674f87" }}>
                                                
                                                    <IconButton onClick={handleExpandAllClick}>
                                                        {allExpanded ? 
                                                        <Tooltip title="Contract All Rules" arrow><KeyboardArrowUpIcon /></Tooltip> : 
                                                        <Tooltip title="Expand All Rules" arrow><KeyboardArrowDownIcon /></Tooltip>}
                                                    </IconButton>
                                                
                                                CONDITIONS
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", color: "#674f87" }}>DELETE</TableCell>
                                            <TableCell sx={{ fontWeight: "bold", color: "#674f87" }}>ADD</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {labelDetails.map((label) => {
                                            const rules = getRulesByLabelId(label.id);
                                            const hasRules = rules.length > 0;

                                            return (
                                                <React.Fragment key={label.id}>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={selectedLabels.includes(label.id)}
                                                                onChange={() => handleCheckboxChange(label.id)}
                                                                inputProps={{ "aria-label": `select label ${label.id}` }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{label.labelName}</TableCell>
                                                        <TableCell>
                                                            {hasRules ?  (
                                                                <Box>
                                                                    <IconButton onClick={() => handleExpandClick(label.id)}>
                                                                        {expandedRow.includes(label.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                    </IconButton>
                                                                    {rules.length > 1 ? `${rules.length} rules available` : rules[0].rule}
                                                                </Box>
                                                            ) : (
                                                                <p style={{ paddingLeft: '40px' }}>No rule is added</p>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rules.length === 1 && (
                                                                <DeleteIcon
                                                                    sx={{ color: "#ef798a", cursor: "pointer" }}
                                                                    onClick={() => handleDeleteRule(rules[0].id)}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <AddIcon
                                                                sx={{ color: "#ef798a", cursor: "pointer" }}
                                                                onClick={() => handleAddRuleClick(label.id)}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    {expandedAddRuleRow.includes(label.id) && addRuleRow === label.id && (
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell colSpan={1}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    placeholder="Enter new rule"
                                                                    value={newRule}
                                                                    onChange={(e) => setNewRule(e.target.value)}
                                                                    sx={{
                                                                        marginLeft: '35px',
                                                                        width: '450px',
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '&.Mui-focused': {
                                                                                '& fieldset': {
                                                                                    borderColor: '#674f87',
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell colSpan={1}>
                                                                <Tooltip title="Save" arrow>
                                                                    <SaveIcon
                                                                        sx={{ color: "#674f87", cursor: "pointer" }}
                                                                        onClick={() => handleSaveRule(label.id)}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell colSpan={1}>
                                                                <Tooltip title="Cancel" arrow>
                                                                    <CancelOutlinedIcon
                                                                        sx={{ color: "red", cursor: "pointer" }}
                                                                        onClick={() => setAddRuleRow(null)}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {expandedRow.includes(label.id) && rules.length > 1 && (
                                                        <TableRow>
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell colSpan={1}>
                                                                <ul style={{ margin: 0, paddingLeft: "50px" }}>
                                                                    {rules.map((rule) => (
                                                                        <li key={rule.id}>{rule.rule}</li>
                                                                    ))}
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell colSpan={1}>
                                                                <Box>
                                                                    {rules.map((rule) => (
                                                                        <DeleteIcon
                                                                            key={rule.id}
                                                                            sx={{ color: "#ef798a", cursor: "pointer", display: "block", marginBottom: "4px" }}
                                                                            onClick={() => handleDeleteRule(rule.id)}
                                                                        />
                                                                    ))}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    )}

                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </TableContainer>
                        {selectedLabels.length > 0 && (
                            <Box
                                sx={{
                                    padding: 4,
                                    borderRadius: 2,
                                    boxShadow: '0px 2px 4px rgb(0,0,0,0.2)',
                                    width: '500px',
                                    height: 400,
                                    marginLeft: '50px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant="h6" mb={2}>
                                    Add Extraction Rule
                                </Typography>
                                <p>You can add an Extraction rule for all the selected labels.</p>
                                <TextField
                                    multiline
                                    rows={6}
                                    placeholder="Enter extraction rule"
                                    variant="outlined"
                                    fullWidth
                                    value={newCondition}
                                    onChange={(e) => setNewCondition(e.target.value)}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused': {
                                                '& fieldset': {
                                                    borderColor: '#674f87',
                                                },
                                            },
                                        },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ alignSelf: 'flex-end', marginTop: 2, background: 'linear-gradient(to right, #ef798a, #674f87 )' }}
                                    onClick={addRule}
                                >
                                    ADD RULE
                                </Button>
                            </Box>
                        )}

                    </Box>
                </Box>
            ) : selectedId === 2 ? (
                <div >
                    <TrainingSetup></TrainingSetup>
                </div>
            ) : selectedId === 1 ? (
                <Box>
                    <LogStream selectedProjectID={selectedProjectID} />
                    {/* <Grid container spacing={4}   style={{ marginBottom: '16px', marginTop:'10px' }} sx={{width:'92%'}}> 
                        
                     <Grid item xs={4}  >
                            <div onClick={create_agent}>
                                <SimpleCard
                                    header='Train'
                                    content='Start Training'
                                    icon= {<EditNoteOutlinedIcon sx={{width:'50px',height:'50px'}}/>}
                                    
                                    />
                            </div>
                        </Grid>
                        {project_details.map((project) => (
                        <Grid item xs={4} key={project.id} >
                            <SimpleCard
                            header={project.header}
                            content={project.content}
                            icon= {<EditNoteOutlinedIcon sx={{width:'50px',height:'50px'}}/>}
                            // click_element={'upload'}
                            />
                        </Grid>
                        ))}
                    </Grid> */}
                </Box>
            ) : (
                <Box style={{marginTop:'15px'}}>
                    <Deploy selectedProjectID={selectedProjectID}/>
                    {/* <Grid container spacing={4}   style={{ marginBottom: '16px', marginTop:'10px' }} sx={{width:'92%'}}> 
                        {project_details.map((project) => (
                        <Grid item xs={4} key={project.id} >
                            <SimpleCard
                            header={project.header}
                            content={project.content}
                            icon= {<ModelTrainingOutlinedIcon sx={{width:'50px',height:'50px'}}/>}
                            // click_element={'upload'}
                            />
                        </Grid>
                        ))}
                    </Grid> */}
                </Box>
            )}
        </Box>
    );
};

export default ProjectSelection;
