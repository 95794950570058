import React, { useEffect, useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PlayArrow, Stop, RocketLaunch, AddCircleOutlineOutlined } from '@mui/icons-material';
import LineChart from '../charts/LineChart';
import TrainingTable from './TrainingTable';
import SegmentedCircularProgress from '../ProgressBar/SegmentedCircularProgres';
import ModelDetails from './ModelDetails'
const apiURL = process.env.REACT_APP_BACKEND_API_URL;
const LogStream = ( args) => {
    const [streaming, setStreaming] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedName, setSelectedName] = useState('Base Model');
    const [openDialog, setOpenDialog] = useState(false);
    const [newModel, setNewModel] = useState('');
    const [selectedId, setSelectedId] = useState(1);
    const currentDate = new Date().toLocaleDateString();

    const selectedProjectID = args.selectedProjectID;
    const [modelVersions, setModelVersions] = useState([
        {
            id: 1,
            name: 'Version 1',
            data: {
                execution_run: { total: 100, passed: 99, failed: 1 },
                status: true,
                start_time: '16:08 PM, 10-12-2024',
                end_time: '17:08 PM, 10-12-2024',
                progress: '30%',
                progress: '30%',
                accuracy: '99.2',
                created:currentDate,
                last_modified:currentDate,
                task_queue: { manual: 250, clustered: 10, prelabeled: 27 },
                label_class_distribution: { completed: 177734, persentage: 65 },
                logs: [
                    {
                        timestamp: "9:36",
                        dataset: "Epoch 45",
                        message:
                            {"log":"End point updated"},
                    },
                    {
                        timestamp: "9:36",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Model saved"},
                    },
                    {
                        timestamp: "9:35",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Training completed"},
                    },
                ],
                accuracy_chart_data: [35, 60, 65, 68, 73, 85,91,95,99]
            }
        },
        {
            id: 2,
            name: 'Version 2',
            data: {
                execution_run: { total: 100, passed: 95, failed: 5 },
                status: false,
                start_time: '18:08 PM, 10-12-2024',
                end_time: '19:08 PM, 10-12-2024',
                progress: '50%',
                accuracy: '95.6',
                created:currentDate,
                last_modified:currentDate,
                task_queue: { manual: 200, clustered: 0, prelabeled: 17 },
                label_class_distribution: { completed: 177734, persentage: 95 },
                logs: [
                    {
                        timestamp: "11:17",
                        dataset: "Epoch 45",
                        message:
                            {"log":"End point updated"},
                    },
                    {
                        timestamp: "11:17",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Model saved"},
                    },
                    {
                        timestamp: "11:17",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Training completed"},
                    },
                ],
                accuracy_chart_data: [75, 75, 78, 81, 85, 89, 94, 95, 95]
            }
        },
    ]);

    // useEffect(() => {
    // const fetchVersions = async () => {
    //       try {
    //           const response = await axios.get(apiURL + `/projects/${selectedProjectID}/models/`);
    //           const projectsData = response.data
    //         //   console.log('projects--,',projectsData)
    //           setModelVersions(projectsData)
    //       } catch (err) {
    //         //   setError(err.response ? err.response.data.detail : 'Error fetching projects');
    //       } finally {
    //         //   setLoading(false);
    //       }
    //   };
    //   fetchVersions();
    // }, []);

    const [options, setOptions] = useState([
        { id: 10, name: 'Agent 1' },
        { id: 20, name: 'Agent 2' }
    ]);
    const [versionData, setVersionData] = useState([
        {
            id: 1,
            name: 'Version 1',
            data: {
                execution_run: { total: 100, passed: 99, failed: 1 },
                status: true,
                start_time: '16:08 PM, 10-12-2024',
                end_time: '17:08 PM, 10-12-2024',
                progress: '30%',
                progress: '30%',
                accuracy: '99.2',
                created:currentDate,
                last_modified:currentDate,
                task_queue: { manual: 250, clustered: 10, prelabeled: 27 },
                label_class_distribution: { completed: 177734, persentage: 65 },
                logs: [
                    {
                        timestamp: "9:36",
                        dataset: "Epoch 45",
                        message:
                            {"log":"End point updated"},
                    },
                    {
                        timestamp: "9:36",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Model saved"},
                    },
                    {
                        timestamp: "9:35",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Training completed"},
                    },
                ],
                accuracy_chart_data: [35, 60, 65, 68, 73, 85,91,95,99]
            }
        },
        {
            id: 2,
            name: 'Version 2',
            data: {
                execution_run: { total: 100, passed: 95, failed: 5 },
                status: false,
                start_time: '18:08 PM, 10-12-2024',
                end_time: '19:08 PM, 10-12-2024',
                progress: '50%',
                accuracy: '95.6',
                created:currentDate,
                last_modified:currentDate,
                task_queue: { manual: 200, clustered: 0, prelabeled: 17 },
                label_class_distribution: { completed: 177734, persentage: 95 },
                logs: [
                    {
                        timestamp: "11:17",
                        dataset: "Epoch 45",
                        message:
                            {"log":"End point updated"},
                    },
                    {
                        timestamp: "11:17",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Model saved"},
                    },
                    {
                        timestamp: "11:17",
                        dataset: "Epoch 45",
                        message:
                            {"log":"Training completed"},
                    },
                ],
                accuracy_chart_data: [75, 75, 78, 81, 85, 89, 94, 95, 95]
            }
        },
    ]);

    const [selectedData, setSelectedData] = useState(versionData[0].data);
    // setModelVersions(versionData)
    function getNameById(id, options) {
        const option = options.find(option => option.id === id);
        return option ? option.name : 'Name not found';
    }

    const handleVersionSelect = (event) => {
        const selectedId = parseInt(event.target.value);
        setSelectedId(selectedId);

        // Find the selected version data based on the selected id
        const selectedVersion = modelVersions.find((item) => item.id === selectedId);
        if (selectedVersion) {
            setSelectedData(selectedVersion.data);
        }
    };


    const handleChange = (event) => {
        if (event.target.value === 'create') {
            setOpenDialog(true);
        } else {
            setSelectedValue(event.target.value);
            setSelectedName(event.target.name);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        setNewModel('');
    };

    const handleCreate = () => {
        const newOption = { id: options.length + 1, name: newModel };
        setOptions([...options, newOption]);
        setSelectedValue(newOption.id);
        setSelectedName(newOption.name);
        handleClose();
    };


    const handleStopStreaming = () => {
        setStreaming(false);
    };

    const handleStartStreaming = () => {
        setStreaming(true);
    };
    const [isLoading, setIsLoading] = useState(false);

    const handleDeploy = async () => {
        setIsLoading(true);
        const ai_text = window.globalVar1;
        //   console.log('ai_text',ai_text)
        //   console.log('selectedValue',getNameById(selectedValue, options))
        try {
            const response = await axios.post(apiURL + `/api/ai-text`, {
                text: ai_text,
                model_name: getNameById(selectedValue, options)
            });
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error sending AI text:', error);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            {/* <Typography variant="h4" color="inherit" >
                Training Stream
            </Typography>
            <Divider /> */}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center",paddingRight:20  }}>
                    <FormControl sx={{ ml: 2, minWidth: 160, paddingRight: 4 }} size="small">
                        <InputLabel id="demo-select-create-label1"
                            sx={{
                                '&.Mui-focused': {
                                    color: '#ef798a',
                                },
                            }}
                        >
                            Model Versions
                        </InputLabel>
                        <Select
                            value={selectedId || ''}
                            label="Model Versions"
                            onChange={handleVersionSelect}
                            sx={{
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#ef798a',
                                },
                                '&.Mui-focused .MuiInputLabel-root': {
                                    color: '#ef798a',
                                },
                            }}
                        >
                            {modelVersions.map((data) => (
                                <MenuItem key={data.id} value={data.id}>
                                    {data.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {streaming ? (
                        <Button
                            variant="contained"
                            onClick={handleStopStreaming}
                            startIcon={<Stop />}
                            sx={{ 
                                background: 'linear-gradient(to right, #ef798a, #674f87)', 
                                marginRight: 2 
                            }}
                        >
                            Stop Training
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleStartStreaming}
                            startIcon={<PlayArrow />}
                            sx={{ 
                                background: 'linear-gradient(to right, #ef798a, #674f87)' 
                            }}
                        >
                            Start Training
                        </Button>
                    )}
                </Box>
            </Box>
            <Box>
                <ModelDetails selectedDatas={selectedData} />
            </Box>
        </Box>
    );
};

export default LogStream;