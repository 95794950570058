import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Authentication from './pages/Authentication/Authentication';
import ExtractData from './pages/ExtractData/ExtractData';
import Hosting from './pages/Hosting/Hosting';
import Functions from './pages/Functions/Functions';
import Database from './pages/Database/Database';
import Storage from './pages/Storage/Storage';
import UploadDoc from './pages/UploadDoc/UploadDoc';
import WhatsNew from './pages/WhatsNew/WhatsNew';
import Documentation from './pages/Documentation/Documentation';
import SupportRequirements from './pages/SupportRequirements/SupportRequirements';
import Annotator from './components/LabelSetup/Annotator';
import Help from  './pages/Help/Help';
import AccountInfo from './pages/AccountInfo/AccountInfo';
import { ThemeProvider } from '@mui/material/styles';
import { dashboardTheme } from './dashboardTheme';
import MyWorkspace from './pages/MyWorkspace/MyWorkspace';
import ConversationalAI from './pages/ConversationalAI/ConversationalAI';
import { NavbarProvider } from './components/Navbar/NavbarContext';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import ModelTraining from './pages/ModelTraining/ModelTraining';
import ImageAnnotator from './components/TrainingSetup/ImageAnnotator';
import ProjectConfig from './components/TrainingSetup/ProjectConfig';
import ImportAnnotation from './components/TrainingSetup/ImportAnnotation';
import ProjectSelection from './components/TrainingSetup/ProjectSelection';
import ModelDetails from './components/TrainingSetup/ModelDetails';
import { useEffect } from 'react';
import { AuthProvider } from './components/AuthContext/AuthContext';
const aigen = process.env.AIGEN_URL;
const trainingenv = process.env.TRAINING_URL;
const taskallocation = process.env.TASK_ALLOCATION_URL;
function RedirectToWorkAllocation() {
  useEffect(() => {
    window.location.href = 'https://taskagent.aichemiste2e.quadance.com/login';
  }, []);

  return null;
}

function RedirectToAiagent() {
  useEffect(() => {
    window.location.href = 'https://aigen.aichemiste2e.quadance.com/home';
  }, []);

  return null;
}

ReactDOM.render(

  <React.StrictMode>
    <NavbarProvider>
      <AuthProvider>
        <ThemeProvider theme={dashboardTheme}>
          <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<App />}>
              <Route path="authentication" element={<Authentication />} />
              <Route path="home" element={<Home />} />
              <Route path="database" element={<Database />} />
              <Route path="functions" element={<Functions />} />
              <Route path="hosting" element={<Hosting />} />
              <Route path="extract-data" element={<ExtractData />} />
              <Route path="extract-data/upload" element={<UploadDoc />} />
              <Route path="whats-new" element={<WhatsNew />} />
              <Route path="documentation" element={<Documentation />} />
              <Route path="support-requirements" element={<SupportRequirements />} />
              <Route path="support-requirements/annotator" element={<Annotator />} />
              <Route path="help" element={<Help />} />
              <Route path="help/project-config" element={<ProjectConfig />} />
              <Route path="help/project-selection" element={<ProjectSelection />} />
              <Route path="help/project-config/import-annotation" element={<ImportAnnotation />} />
              <Route path="help/project-selection/image-annotation" element={<ImageAnnotator />} />
              <Route path="help/project-selection/model-training" element={<ModelTraining />} />
              <Route path="model-training" element={<Help />} />
              <Route path="model-training/project-config" element={<ProjectConfig />} />
              <Route path="model-training/project-selection" element={<ProjectSelection />} />
              <Route path="model-training/project-config/import-annotation" element={<ImportAnnotation />} />
              <Route path="model-training/project-selection/image-annotation" element={<ImageAnnotator />} />
              <Route path="model-training/project-selection/model-training" element={<ModelTraining />} />
              
              <Route path="account-info" element={<AccountInfo />} />
              <Route path="storage" element={<Storage />} />
              <Route path="my-workspace" element={<MyWorkspace />} />
              <Route path="conversational-ai" element={<ConversationalAI />} />
              {/* <Route path="model-training" element={<ModelTraining />} /> */}
              <Route path="model-details" element={<ModelDetails />} />
              <Route path="model-training/image-annotation" element={<ImageAnnotator />} />
              
              <Route path="WorkAllocation" element={<RedirectToWorkAllocation />} />
              <Route path="aiAgent" element={<RedirectToAiagent />} />
            </Route>
          </Routes>
        </BrowserRouter>
        </ThemeProvider>
        </AuthProvider>
    </NavbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();