import HomeIcon from '@mui/icons-material/Home';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import GppGoodIcon from '@mui/icons-material/GppGood';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DescriptionIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/Support';
import HelpIcon from '@mui/icons-material/Help';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TryIcon from '@mui/icons-material/Try';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';

export const mainNavbarItems = [
    // {
    //     id: 0,
    //     icon: <HomeIcon />,
    //     label: 'My Workspace',
    //     route: 'my-workspace',
    // },
    // {
    //     id: 13,
    //     icon: <HelpIcon />,
    //     label: 'Home',
    //     route: 'home',
    // },
    // {
    //     id: 1,
    //     icon: <DocumentScannerIcon />,
    //     label: 'Extract Data',
    //     route: 'extract-data',
    // },
    // {
    //     id: 2,
    //     icon: <TryIcon />,
    //     label: 'Conversational AI',
    //     route: 'conversational-ai',
    // },
    {
        id: 3,
        icon: <ModelTrainingIcon />,
        label: 'Model Training',
        route: 'model-training',
    },
    // {
    //     id: 4,
    //     icon: <SettingsSuggestIcon />,
    //     label: 'Orchestrator',
    //     route: 'functions',
    // },
    // {
    //     id: 5,
    //     icon: <GppGoodIcon />,
    //     label: 'QC Console',
    //     route: 'support-requirements',
    // },
    {
        id: 6,
        icon: <GppGoodIcon />,
        label: 'Task Allocation',
        route: 'WorkAllocation',
    },
    {
        id: 7,
        icon: <GppGoodIcon />,
        label: 'Ai Agent',
        route: 'aiAgent',
    },
    // {
    //     id: 8,
    //     icon: <GppGoodIcon />,
    //     label: 'Project',
    //     contents: [ { id: 1, header: 'Train Model', icon: ModelTrainingOutlinedIcon , route: 'abcs', },
    //                 { id: 2, header: 'Annote Dataset', icon: FormatShapesOutlinedIcon, route: 'xtyx',  },
    //                 { id: 3, header: 'Add Extraction Rules', icon: RuleFolderOutlinedIcon, route: 'dfghds', }
    //             ],
    //     route: 'support-requirements',
    // },
]

export const mainProfileItems = [
    {
        id: 10,
        icon: <NewReleasesIcon />,
        label: 'Whats New',
        route: 'whats-new',
    },
    {
        id: 11,
        icon: <DescriptionIcon />,
        label: 'Documentation',
        route: 'documentation',
    },
    {
        id: 12,
        icon: <SupportAgentIcon />,
        label: 'Support Requirements',
        route: 'support-requirements',
    },
    {
        id: 14,
        icon: <AccountBoxIcon />,
        label: 'Account Info',
        route: 'account-info',
    },
    

]