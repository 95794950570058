import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import { TextField, Button, Box, Typography, IconButton, Divider, Switch } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import GridWrapper from '../common/GridWrapper/GridWrapper';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DropBox from './DropBox'
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TwitterPicker } from 'react-color';
import { SketchPicker } from 'react-color';
import { ColorLens as ColorLensIcon } from '@mui/icons-material';
import Popover from '@mui/material/Popover';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import Select from 'react-select'

const apiURL = process.env.REACT_APP_BACKEND_API_URL;

export default function ProjectConfig() {
    const [tabvalue, setTabValue] = React.useState('1');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [selectedColor, setSelectedColor] = useState('#FF0000');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [labelsInitial, setLabelsInitial] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dataTypeData, setDataType] = React.useState('Alphanumeric');
    const [extractionTypeData, setExtractionType] = React.useState('Field');

    const { state } = useLocation();
    const { list } = state || { list: [] };  // Retrieve the list passed from CreateModel

    // console.log('list--',list)
    const [labels, setLabels] = React.useState([]);
    // console.log('labels-*-',labels)
    const [formData, setFormData] = useState({
        name: '',
        project_type: list.header,
        training_type: 'Custom Trained',
        parent_model: String(list.id),
        description: '',
        meta: {},
        bucket_location: 'base_loc',
    });
    const createProject = async () => {
        try {
            const updatedFormData = {
                ...formData,
                projectLabels: labels,
            };
            // console.log('labels-**-',labels)

            // console.log('Sending data:', updatedFormData);

            const response = await fetch(apiURL + '/projects/customproject', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFormData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Server response:', data);
            navigate("/model-training", { replace: true });
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };
    const cancelProject = async () => {
        try {
            navigate("/model-training", { replace: true });
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };
    useEffect(() => {
        console.log('----IN----')
        const fetchCustomProjects = async (project_id) => {
            try {
                const response = await axios.get(apiURL + `/projects/${project_id}/labels/`);
                const projectsData = response.data.map(project => ({
                    ...project
                }));
                console.log('projectsData', projectsData)
                setLabels(projectsData);
            } catch (err) {
                setError(err.response ? err.response.data.detail : 'Error fetching projects');
            } finally {
                setLoading(false);
            }
        };
        fetchCustomProjects(list.id);
        console.log('labels---', labels);
    }, []);
    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;


    const handleColorChange = (color) => {
        setSelectedColor(color.hex);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const modelDetails = [
        { value: '1', label: 'Model_1' },
        { value: '2', label: 'Model_2' },
        { value: '3', label: 'Model_3' },
    ];

    const TypeDetails = [
        { value: '1', label: 'Alpha' },
        { value: '2', label: 'Numeric' },
        { value: '3', label: 'Alphanumeric' },
    ];
    const LabelTypeDetails = [
        { value: '1', label: 'Field' },
        { value: '2', label: 'Table' }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log('formData', formData)
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(formData);
        navigate("project-selection");
    };

    const handleImport = () => {
        navigate("import-annotation");
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [labelType, setLabelType] = useState('');
    const onChangeLabelType = (event) => {
        const value = event.target.value;
        setExtractionType(value)
        console.log('labelName***', value)
    };
    const onChangeDataType = (event) => {
        const value = event.target.value;
        setDataType(value)
        console.log('labelName***', value)
    };

    const handleAddLabel = () => {
        const labelName = document.getElementById('name').value;
        const multiLine = document.getElementById('multiLine').checked;
        const color = selectedColor;
        console.log('labelName', document.getElementById('name'))
        console.log('extraction_type', extractionTypeData)
        console.log('dataType', dataTypeData)
        const newLabel = {
            name: labelName,
            extraction_type: extractionTypeData,
            data_type: dataTypeData,
            multi_line: multiLine,
            color: color,
            active: true, // defaulting to true, you can change this as needed
            annotations: {},
            meta: {},
            label_model_map: "1",
            project_id: "0"
        };

        setLabels([...labels, newLabel]);
        // handleClose();
        console.log('TTTTlabels--', labels)
    }

    const gradientStyle = {
        paddingBottom: '10px',
        borderBottom: '3px solid #674f87'
    };

    const handleToggleLabel = (labelName) => {
        setLabels(prevLabels =>
            prevLabels.map(label =>
                label.name === labelName ? { ...label, active: !label.active } : label
            )
        );
    };
    return (
            <Box sx={{ display: 'block', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant="h4" sx={{paddingBlock:1, color:"#5d5e5e", background: "#f5f5f5", textAlign:"center", fontWeight: "bold"}}>
                    Configure New Project
                </Typography>
                <Divider/>
                <Box component="form" onSubmit={handleSubmit}
                    sx={{ display: 'flex', width:1400 , marginBlock:2, marginInline:"auto",borderRadius:2 , boxShadow: 2, padding: 2, gap:3}}
                >
                    <TextField
                        label="Project name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        sx={{width: 400 }}
                        InputProps={{ sx: { height: '45px'} }}
                    />
                    <TextField
                        label="Project Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        variant="outlined"
                        required
                        sx={{width: 800 }}
                        InputProps={{ sx: { height: '45px'} }}
                    />
                    <Button onClick={createProject} variant="contained"
                        startIcon={<SaveIcon/>}
                        sx={{background: 'linear-gradient(to right, #ef798a, #674f87 )',
                        }}
                    >
                        Save
                    </Button>
                    <Button onClick={cancelProject} variant="contained"
                        startIcon={<CancelIcon/>}
                        sx={{background: 'linear-gradient(to right, #ef798a, #674f87 )',
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
                <Box  sx={{ width: 1400, borderRadius:5, boxShadow: 2, marginBlock: 2, marginInline:"auto",    }}>
                    <Typography variant="h5" sx={{ color: '#5d5e5e', textAlign: 'center', marginTop:1, background:"#f5f5f5", borderRadius: "15px 15px 0 0"}}>
                        Model Details
                    </Typography>
                    {/* <Typography sx={{mt: 1, ml:1}}>
                        Train models once all labels have at least 'n' examples
                    </Typography> */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={handleClickOpen}
                            startIcon={<AddIcon/>}
                            sx={{ 
                                background: 'linear-gradient(to right, #ef798a, #674f87)', 
                                margin: 2 
                            }}
                        >
                            ADD LABEL
                        </Button>
                    </Box>
                    <React.Fragment>
                        <Dialog
                            maxWidth="xs"
                            fullWidth
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                component: 'form',
                                onSubmit: (event) => {
                                    event.preventDefault();
                                    handleAddLabel();
                                },
                            }}
                        >
                            <DialogTitle sx={{ fontWeight: 'bold' }}>Add Label</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="name"
                                    label="Label Name"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ sx: { height: '45px' } }}
                                />
                            </DialogContent>
                            <DialogContent>
                                <FormControl variant="outlined" required fullWidth>
                                    <select onChange={onChangeLabelType} className="form-select">
                                        <option defaultValue disabled>
                                            Lable Type
                                        </option>
                                        <option value="Field">Field</option>
                                        <option value="Table">Table</option>\
                                    </select>
                                </FormControl>
                            </DialogContent>

                            <DialogContent>
                                <FormControl variant="outlined" required fullWidth>
                                    <select onChange={onChangeDataType} className="form-select">
                                        <option defaultValue disabled>
                                            Data Type
                                        </option>
                                        <option value="Alpha">Alpha</option>
                                        <option value="Numeric">Numeric</option>
                                        <option value="Alphanumeric">Alphanumeric</option>
                                    </select>
                                </FormControl>
                            </DialogContent>
                            <DialogContent>
                                <FormControlLabel
                                    control={<Checkbox id="multiLine" sx={{ '&.Mui-checked': { color: '#ef798a' } }} />}
                                    label="Multiline Label"
                                />
                            </DialogContent>
                            <DialogContent sx={{ display: 'flex', position: 'relative' }}>
                                <TextField
                                    margin="dense"
                                    id="color"
                                    label="Color"
                                    type="text"
                                    variant="outlined"
                                    value={selectedColor}
                                    InputProps={{ readOnly: true, sx: { height: '45px' } }}
                                />
                                <Box
                                    aria-describedby={id}
                                    variant="contained"
                                    onClick={handleClickPopover}
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        border: '3px solid black',
                                        borderRadius: '1px',
                                        backgroundColor: selectedColor,
                                        position: 'absolute',
                                        right: 170,
                                        top: 35
                                    }}
                                />
                                <Popover
                                    id={id}
                                    open={openPopover}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePopover}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left'
                                    }}
                                >
                                    <Box>
                                        <SketchPicker color={selectedColor} onChangeComplete={handleColorChange} />
                                    </Box>
                                </Popover>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    onClick={handleClose}
                                    sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)' }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ background: 'linear-gradient(to right, #ef798a, #674f87)' }}
                                >
                                    ADD
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                    <TabContext value={tabvalue} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab sx={{ fontWeight: 'bold' }} label={`Field`} value="1" />
                                <Tab sx={{ fontWeight: 'bold' }} label="Table" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" >
                            <Box style={{ display: 'flex'}}>
                                <h4 style={{ color: '#5d5e5e' }}>LABELS({labels.filter(label => label.active && label.extraction_type === 'Field').length})</h4>
                            </Box>
                            <TableContainer component={Paper} sx={{height: 400, border:"2px solid #d8d8d8"}}>
                                <Table>
                                    <TableHead sx={{background:"#f5f5f5"}}>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: "bold", fontSize: 18, textAlign: "center", color:"#5d5e5e"}}>Label Name</TableCell>
                                            <TableCell sx={{fontWeight: "bold", fontSize: 18, textAlign: "center", color:"#5d5e5e"}}>Status</TableCell>
                                            <TableCell sx={{fontWeight: "bold", fontSize: 18, textAlign: "center", color:"#5d5e5e"}}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {labels.filter(label => label.extraction_type === 'Field').map((label) => (
                                            <TableRow key={label.name}>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        value={label.name}
                                                        placeholder="Label Name"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            sx: {
                                                                '& .MuiInputBase-input': {
                                                                  textAlign: 'center',
                                                                },
                                                              },
                                                          }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        value={label.active ? 'Active' : 'Inactive'}
                                                        placeholder="Status"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            sx: {
                                                                '& .MuiInputBase-input': {
                                                                  textAlign: 'center',
                                                                },
                                                              },
                                                          }}
                                                    />
                                                </TableCell>
                                                <TableCell >
                                                    {/* <Button
                                                        variant="contained"
                                                        onClick={() => handleToggleLabel(label.name)}
                                                        sx={{ background: label.active ? 'green' : 'red', color: '#fff'}}
                                                    >
                                                        {label.active ? 'Deactivate' : 'Activate'}
                                                    </Button> */}
                                                    <Switch
                                                        checked={label.active}
                                                        onChange={() => handleToggleLabel(label.name)}
                                                        color="success"
                                                        sx={{
                                                            marginLeft:25,
                                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                                color: '#fff',
                                                            },
                                                            '& .MuiSwitch-track': {
                                                                backgroundColor: label.active ? 'green' : 'red',
                                                            },
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value="2">
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '65%' }}>
                                    <h4 style={{ color: '#5d5e5e' }}>LABELS({labels.filter(label => label.active && label.extraction_type === 'Table').length})</h4>
                                </div>
                            </div>
                            <TableContainer component={Paper} sx={{height: 400, border:"2px solid #d8d8d8"}}>
                                <Table>
                                    <TableHead sx={{background:"#f5f5f5"}}>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: "bold", fontSize: 18, textAlign: "center", color:"#5d5e5e"}}>Label Name</TableCell>
                                            <TableCell sx={{fontWeight: "bold", fontSize: 18, textAlign: "center", color:"#5d5e5e"}}>Status</TableCell>
                                            <TableCell sx={{fontWeight: "bold", fontSize: 18, textAlign: "center", color:"#5d5e5e"}}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {labels.filter(label => label.extraction_type === 'Table').map((label) => (
                                            <TableRow key={label.name}>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        value={label.name}
                                                        placeholder="Label Name"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            sx: {
                                                                '& .MuiInputBase-input': {
                                                                  textAlign: 'center',
                                                                },
                                                              },
                                                          }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        value={label.active ? 'Active' : 'Inactive'}
                                                        placeholder="Status"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true,
                                                            sx: {
                                                                '& .MuiInputBase-input': {
                                                                  textAlign: 'center',
                                                                },
                                                              },
                                                          }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {/* <Button
                                                        variant="contained"
                                                        onClick={() => handleToggleLabel(label.name)}
                                                        sx={{ background: label.active ? 'green' : 'red', color: '#fff' }}
                                                    >
                                                        {label.active ? 'Deactivate' : 'Activate'}
                                                    </Button> */}
                                                    <Switch
                                                        checked={label.active}
                                                        onChange={() => handleToggleLabel(label.name)}
                                                        color="success"
                                                        sx={{
                                                            marginLeft:25,
                                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                                color: '#fff',
                                                            },
                                                            '& .MuiSwitch-track': {
                                                                backgroundColor: label.active ? 'green' : 'red',
                                                            },
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
    );

}