import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import BasicCard from '../common/BasicCard/BasicCardIconT2'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EntryIcon from '../images/Entry.svg';
import HandIcon from '../images/Handwritten.svg';
import TableIcon from '../images/Table.svg';
import TamilIcon from '../images/Tamil.svg';
import DirectoryIcon from '../images/Directory.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, CardActionArea, Button } from '@mui/material';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { ModelTraining } from '@mui/icons-material';
import { useNavbar } from '../Navbar/NavbarContext';
const apiURL = process.env.REACT_APP_BACKEND_API_URL;

const CreateModel = () => {
  const [base_project, setBaseProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trainingType, setTrainingType] = useState("Base");
  const [createList, setCreateList] =  useState([]);
  const [projects, setProjects] =  useState([]);
  const iconMap = {
      DocumentScannerOutlinedIcon: DocumentScannerOutlinedIcon,
      ReceiptOutlinedIcon: ReceiptOutlinedIcon,
      ReceiptLongOutlinedIcon: ReceiptLongOutlinedIcon,
      DescriptionOutlinedIcon: DescriptionOutlinedIcon
  };

  const { setCollapsed } = useNavbar();
    setCollapsed(false);

  const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    borderRadius: '15px',
    background: 'linear-gradient(135deg, #ef798a, #674f87)',
    transition: '0.3s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    },
}));  
  useEffect(() => {
      const fetchProjects = async () => {
          try {
              const response = await axios.get(apiURL + `/projects/training_type/Base`);
              const projectsData = response.data.map(project => ({
                  ...project,
                  icon: iconMap[project.icon] // Map the icon string to the actual icon component
              }));
              setCreateList(projectsData);
          } catch (err) {
              setError(err.response ? err.response.data.detail : 'Error fetching projects');
          } finally {
              setLoading(false);
          }
      };
      const fetchCustomProjects = async () => {
          try {
              const response = await axios.get(apiURL + `/projects/training_type/Custom Trained`);
              const projectsData = response.data.map(project => ({
                  ...project,
                  icon: iconMap[project.icon] || DescriptionOutlinedIcon // Map the icon string to the actual icon component
              }));
              setProjects(projectsData);
              // console.log('projects--,',projectsData)
          } catch (err) {
              setError(err.response ? err.response.data.detail : 'Error fetching projects');
          } finally {
              setLoading(false);
          }
      };

      fetchProjects();
      fetchCustomProjects();
  }, [trainingType]);

console.warn("CreateList", createList)
  const navigate = useNavigate();

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleHeaderHover = () => {
    setIsHovered(true);
  };

  const handleHeaderLeave = () => {
    setIsHovered(false);
  };
  const handleDoneClick = (list) => {
    const serializableList = {
      id: list.project_id,
      content: list.content,
      header: list.header,
      icon: list.icon.displayName // If you need to pass the icon name
    };
    navigate("project-config", { state: { list: serializableList } });
  };
  const exploreMoreStyles = {
  
    moreContainer2: {
      padding:'10px 0px' 
      // Add any styles for the more container here
    },
    moreContainerH1: {
      width: 'fit-content',
      padding: '2px 0px',
      fontSize: '20px',
    },
    imageContainer: {
      display: 'inline-block',
      marginRight: '50px',
    },
    imageBox: {
      width: '200px',
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(to right, #ef798a 1%, #674f87 40%)',
      borderRadius: '25px',
      cursor: 'pointer',
      transition: 'transform 0.3s ease', // Adding transition for smoother hover effect
      transform: 'scale(1)', // Initial scale
    },
    imageBoxHover: {
      transform: 'scale(1.1)', // Enlarge the image box on hover
    },
    imageBoxImg: {
      maxWidth: '100%',
      maxHeight: '100%',
      cursor: 'pointer',
    },
    description: {
      width: '200px',
      textAlign: 'center',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '20px',
      cursor: 'pointer',
      color: '#000',
      transition: 'color 0.3s ease',
    },
    descriptionHover: {
      color: '#7c7a7a',
    },
  };


  return (
    <div>
      <Typography variant="h5" component="h2" sx={{ display: 'flex', alignItems: 'center', fontWeight:"bold", marginTop:1 }}>
      <AccountTreeIcon sx={{ fontSize: 30 ,ml: 1, verticalAlign: 'bottom' }} />
        Create Project
      </Typography>
      <Divider/>
      <Box sx={{marginBlock:5}}>
          <Grid container spacing={1}  sx={{display:"flex"}}>
          {createList.map((list) => (
            <Grid item xs={2.5} key={list.id} sx={{ display: 'flex' }} onClick={() => handleDoneClick(list)}>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                  display: 'flex',
                  flexDirection: 'row',
                  maxWidth: 250,
                  width: '100%',
                  background: '#fff',
                  transition: 'transform 0.3s ease',
                  '&:hover': { boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)' } 
                }}
              >
                <Box 
                  sx={{ display: 'flex', 
                        alignItems: 'center', 
                        justifyContent:"center",
                        borderRadius:1, 
                        // border:"1px solid #000", 
                        width:"30px", 
                        height:"30px",
                        background: `linear-gradient(to right, #9E3C5D, #432F60)`,
                        marginRight:1
                      }}
                >
                  <DocumentScannerOutlinedIcon sx={{width:"20px", height:"20px", color:"#fff" }}/>
                  {/* <AccountTreeIcon sx={{width:"20px", height:"20px"}}/> */}
                </Box>
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>                  
                  <Typography variant="h6" textAlign={"center"} fontWeight={"bold"}>
                    {list.header}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            ))}
          </Grid>
      </Box>
      <div>
          <Typography variant="h5" component="h2" sx={{ display: 'flex', alignItems: 'center', fontWeight:"bold"}}>
          <AccountTreeIcon sx={{ fontSize: 30, ml: 1, verticalAlign: 'bottom' }} />
            Your projects
          </Typography>
          <Divider/>
          <Box sx={{marginBlock: 5}}>
            <Grid container spacing={4} sx={{width:'92%'}}> 
              {projects.map((project) => (
                <Grid item xs={4} key={project.id}>
                  <BasicCard
                    header={project.header}
                    content={project.content}
                    icon={project.icon}
                    click_element={'project-selection'}
                    project_id={project.project_id}
                  />
                </Grid>
                ))}
              </Grid>
          </Box>
      </div>
    </div>
  ); 
};

export default CreateModel;
