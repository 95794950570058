import React, { useState, useEffect } from "react"; import {
    Box,
    Typography,
    Button,
    Card,
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    Grid,
    Chip
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { CheckCircle } from '@mui/icons-material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LineChart from '../charts/LineChart';
import TrainingTable from './TrainingTable';

ChartJS.register(ArcElement, Tooltip, Legend);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
        ...theme.applyStyles('dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));

function ModelDetails( {selectedDatas}) {
    const [isActive, setisActive] = useState(false);
    useEffect(() => {
        setisActive(selectedDatas.status);
    })

    const data = {
        labels: ['Passed'],
        datasets: [
            {
                data: [selectedDatas.execution_run.passed, selectedDatas.execution_run.failed],
                backgroundColor: ['#6B5087', '#d9d9d9'],
                hoverBackgroundColor: ['#EA788A', '#d9d9d9'],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        cutout: '70%',
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        },
    };

    const executionItems = [
        { name: 'Training', content: '1 test: Element not found' },
        { name: 'Validation', content: '1 test: Element not found' },
        { name: 'Inference', content: '1 test: Element not found' },
        { name: 'Featurization', content: '1 test: Element not found' }
    ];

    const recentList = [
        { activity: 'Modified pull request', time: '2 hours ago' },
        { activity: 'Merged', time: '3 hours ago' },
        { activity: 'Merge requested', time: '4 hours ago' },
        { activity: 'Modified pull request', time: '8 hours ago' },
    ];

    return (
        <Box p={4} bgcolor="#ffffff" minHeight="100vh">
            <Typography variant="h4" fontWeight="bold">
                Model Details
            </Typography>
            <Divider />
            
            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ p: 3, textAlign: 'center' }}>
                        <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" mb={1}>
                            OVERALL ACCURACY
                        </Typography>
                        <Doughnut data={data} options={options} style={{ maxHeight: '180px', marginTop: 16, cursor:'pointer' }} />
                        <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
                            <Stack alignItems="center">
                                <Typography variant="h6" color="textSecondary">
                                    {selectedDatas.execution_run.passed}%
                                </Typography>
                            </Stack>
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Stack spacing={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <Card sx={{ p: 2, textAlign: 'center', height: '120px', alignContent:'center'}}>
                                    <Typography variant="subtitle2" fontWeight="bold" color="textSecondary">
                                        STATUS
                                    </Typography>
                                    {isActive ?(
                                        <Chip icon={<CheckCircleOutlineIcon />} label="Active" color="success" variant="outlined" />
                                    ) : (
                                        <Chip icon={<CancelOutlinedIcon />} label="Inactive" color="error" variant="outlined" />
                                    )} 
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Card sx={{ p: 2, textAlign: 'center', height: '120px', alignContent:'center' }}>
                                    <Typography variant="subtitle2" fontWeight="bold" color="textSecondary">
                                        CREATION DATE
                                    </Typography>
                                    <Typography variant="body1" fontWeight="bold">
                                        {selectedDatas.created}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Card sx={{ p: 2, textAlign: 'center', height: '120px', alignContent:'center' }}>
                                    <Typography variant="subtitle2" fontWeight="bold" color="textSecondary">
                                         LAST UPDATED
                                    </Typography>
                                    <Typography variant="body1" fontWeight="bold">
                                        {selectedDatas.last_modified}
                                    </Typography>
                                </Card>
                            </Grid>
                            {/* <Grid item xs={12} sm={3}>
                                <Card sx={{ p: 2, textAlign: 'center', height: '120px', alignContent:'center' }}>
                                    <Typography variant="subtitle2" fontWeight="bold" color="textSecondary">
                                        PROGRESS
                                    </Typography>
                                    <Typography variant="h5" fontWeight="bold">
                                        {selectedDatas.progress}
                                    </Typography>
                                </Card>
                            </Grid> */}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Card sx={{ p: 2, height: '140px' }}>
                                    <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" mb={1}>
                                        Training Config
                                    </Typography>
                                    <Divider sx={{ borderWidth: 2 }} />
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
                                        <Box textAlign="center" sx={{ flex: 1 }}>
                                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{selectedDatas.task_queue.manual}</Typography>
                                            <Typography variant="body2" color="text.secondary">Documents</Typography>
                                        </Box>
                                        <Divider orientation="vertical" flexItem sx={{ borderColor: '##308FE8', borderRightWidth: 3 }} />
                                        <Box textAlign="center" sx={{ flex: 1 }}>
                                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{selectedDatas.task_queue.clustered}</Typography>
                                            <Typography variant="body2" color="text.secondary">Labels</Typography>
                                        </Box>
                                        <Divider orientation="vertical" flexItem sx={{ borderColor: '##308FE8', borderRightWidth: 3 }} />
                                        <Box textAlign="center" sx={{ flex: 1 }}>
                                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{selectedDatas.task_queue.prelabeled}</Typography>
                                            <Typography variant="body2" color="text.secondary">Tested</Typography>
                                        </Box>
                                    </Stack>
                                </Card>
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <Card sx={{ p: 2, height: '170px' }}>
                                    <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" mb={1}>
                                        Label Class Distribution
                                    </Typography>
                                    <Divider sx={{ borderWidth: 2 }} />
                                    <Box mt={2}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                            <Typography variant="body2">Label</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {selectedDatas.label_class_distribution.completed} ({selectedDatas.label_class_distribution.persentage}%)
                                            </Typography>
                                        </Box>
                                        <BorderLinearProgress variant="determinate" value={selectedDatas.label_class_distribution.persentage} />
                                    </Box>
                                </Card>
                            </Grid> */}
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container spacing={3} mt={1}>
                <Grid item xs={12} md={6}>
                    <TrainingTable logdata={selectedDatas.logs}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LineChart chartData={selectedDatas.accuracy_chart_data}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ModelDetails;
