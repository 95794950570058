
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { cardStyles } from './styles';
export default function BasicCard({ header, content, icon, click_element, project_id }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClick = (click_element) => {
    console.log('selected id ', project_id)
    console.log('swindow.globalVar1 ', window.globalVar1)
    window.globalVar1 = project_id;
    navigate(click_element);
  };
  const IconComponent = icon;
  return (
    <Card
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        display: 'flex',
        transition: 'border-color 0.3s ease',
        // border: '1px solid transparent',
        boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          // borderColor: '#674f87',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
          '& .header-typography': {
            color: 'linear-gradient(to right, #ef798a, #674f87)',
          },
        },
        '& span': {
          color: '#555555',
        },
      }}
      onClick={() => handleClick(click_element, project_id)}
    >
      <Box
        sx={{
          width: '20%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            width: '80%',
            height: '80%',
            borderRadius: '10px',
            border:"2px solid #9E3C5D"
          }}
        >
          <CardMedia
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              backgroundColor: '#ffffff',
              '& img': {
                color: '#674f87',
                fontSize: '3rem',
                width: '50px',
                height: '50px',
              },
            }}
          >
            <IconComponent />
          </CardMedia>
        </Card>
      </Box>

      {/* Box with 80% width */}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography
            component="div"
            variant="h9"
            className="header-typography"
          >
            {header}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {content}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}